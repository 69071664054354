import React, { useState, useEffect } from "react";
import { Box, Typography, styled, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import HaefniImage from "assets/logos/haefni2.jpg";
import KristjanImage from "assets/logos/kristjan_image.png";
import GlodisImage from "assets/logos/glodis_image.png";
import CorporateImage from "assets/logos/corporate_learning_coach_image.png";

import GermanyFlag from "assets/icons/de_flag.png";
import UKFlag from "assets/icons/us_uk_flag.png";
import SpainFlag from "assets/icons/es_flag.png";
import TurkeyFlag from "assets/icons/tr_flag.png";
import PolandFlag from "assets/icons/pl_flag.png";
import ItalyFlag from "assets/icons/it_flag.png";
import FranceFlag from "assets/icons/fr_flag.png";

import RedHeart from "assets/icons/filled_heart.png";
import GreyHeart from "assets/icons/red_unfilled_heart.png";

const assistants = [
  {
    id: 1,
    name: "Hæfni",
    img: HaefniImage,
    description: {
      en: "Hæfni gives you examples and explanations, tells vivid little stories from the business world, humorous.",
      de: "Gibt dir Beispiele und Erläuterungen, erzählt anschaulich kleine Stories aus der Businesswelt, humorvoll.",
    },
    form: {
      en: "She uses a casual speech style",
      de: "Verwendet die Du-Form",
    },
    available: true,
    activeLanguages: [GermanyFlag],
  },
  {
    id: 2,
    name: "Kristján",
    img: KristjanImage,
    description: {
      en: "He uses a rather formal speech style",
      de: "Vermittelt Ihnen die wichtigsten Tipps & Tricks kurz und knapp, schnörkellos auf den Punkt.",
    },
    form: {
      en: "He uses a rather formal speech style. ",
      de: "Verwendet die Sie-Form",
    },
    available: true,
    activeLanguages: [GermanyFlag],
  },
  {
    id: 3,
    name: "Glódís",
    img: GlodisImage,
    description: {
      en: "Glódís explains the best tips briefly and clearly. Some details and topics are omitted.",
      de: "Formuliert verständlich in einfacher Sprache. Einige Details und Themen werden weggelassen.",
    },
    form: {
      en: "She uses a simple language. ",
      de: "Verwendet die Du-Form",
    },
    available: true,
    activeLanguages: [GermanyFlag],
  },
  {
    id: 4,
    name: "Corporate",
    img: CorporateImage,
    description: {
      en: "The avatar and look & feel of the chatbot can be customized according to your corporate design.",
      de: "Avatar und Look & Feel des Chatbots kann nach Ihrem Corporate Design ausgestattet werden.",
    },
    form: {
      en: "",
      de: "Du oder Sie-Form",
    },
    available: true,
    activeLanguages: [GermanyFlag],
  },
];

const languages = [
  { flag: GermanyFlag, active: true },
  { flag: UKFlag, active: false },
  { flag: SpainFlag, active: false },
  { flag: ItalyFlag, active: false },
  { flag: PolandFlag, active: false },
  { flag: TurkeyFlag, active: false },
  { flag: FranceFlag, active: false },
];

const Container = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  width: "100%",
  minHeight: "100vh",
  padding: "5rem 1rem 3rem 1rem",
  overflow: "visible",

  "@media (max-width: 1024px)": {
    paddingTop: "3rem",
  },

  "@media (max-width: 768px)": {
    paddingTop: "2rem",
  },
});


const CardContainer = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  alignItems: "center",
  gap: "1.5rem",
  width: "100%",
  padding: "1rem",

  "@media (max-width: 768px)": {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "1rem 0",
    gap: "3rem",
  },
});

const AssistantCard = styled(Box)<{ selected?: boolean }>(({ selected }) => ({
  position: "relative",
  width: "300px",
  height: "520px",
  padding: "1.5rem",
  paddingBottom: "2rem",
  borderRadius: "25px",
  textAlign: "center",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
  border: selected ? "6px solid #a1b7a5" : "6px solid #bfbfbf",
  backgroundColor: "white",
  transition: "transform 0.3s ease, opacity 0.3s ease",
  cursor: "pointer",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  margin: "auto",

  "@media (max-width: 768px)": {
    width: "90%", 
    height: "auto",
    padding: "1rem",
  },
}));

const AvatarWrapper = styled(Box)({
  width: "140px",
  height: "140px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});


const AvatarImage = styled("img")({
  width: "100%",
  height: "100%",
  objectFit: "contain",
});

const NameText = styled(Typography)({
  fontSize: "2rem",
  fontWeight: "bold",
  textAlign: "center",
  gap: "0.4rem",
  letterSpacing: "0.05em",
  "@media (max-width: 1024px)": {
    fontSize: "1.8rem",
  },
  "@media (max-width: 768px)": { 
    fontSize: "1.5rem",
  },
});

const DescriptionText = styled(Typography)({
  fontSize: "1rem",
  textAlign: "center",
  color: "#333",
  padding: "0 1rem",
  maxWidth: "95%",
  overflow: "hidden",
  textOverflow: "ellipsis", 
  "@media (max-width: 1024px)": {
    fontSize: "0.9rem",
  },
  "@media (max-width: 768px)": { 
    fontSize: "0.85rem",
    padding: "0 0.5rem",
  },
});

const FormText = styled(Typography)({
  fontSize: "1rem",
  fontWeight: "bold",
  color: "#4A4A4A",
  marginTop: "0.5rem",
  marginBottom: "0.3rem",
});

const LanguageContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "0.5rem",
  marginTop: "1rem",

  "@media (max-width: 768px)": { 
    flexDirection: "column",
    gap: "0.7rem",
    marginTop: "1.5rem",
  },
});

const LanguageIcons = styled(Box)({
  display: "flex",
  justifyContent: "center",
  gap: "8px",
  alignItems: "center",
});

const LanguageCircle = styled(Box)(({ active }: { active?: boolean }) => ({
  width: "28px",
  height: "28px",
  aspectRatio: "1 / 1",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  backgroundColor: active ? "transparent" : "#d3d3d3",
  filter: active ? "none" : "grayscale(100%)",
  overflow: "hidden",
  boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.2)",
  transition: "transform 0.2s ease",
  "&:hover": {
    transform: "scale(1.1)",
  },
}));

const ComingSoonText = styled(Typography)({
  fontSize: "0.95rem",
  fontWeight: "bold",
  color: "red",
  marginTop: "0.5rem",
});

const HeartContainer = styled(Box)({
  position: "absolute",
  top: "-10%",
  left: "50%",
  transform: "translateX(-50%)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  transition: "transform 0.2s ease",

  "@media (max-width: 768px)": {
    top: "-5%",
  },
});

const SkillaryButton = styled(Button)(({ disabled }: { disabled: boolean }) => ({
  marginTop: "2rem",
  padding: "0.3rem 5rem",
  borderRadius: "0px",
  backgroundColor: disabled ? "#d3d3d3" : "#a1b7a5",
  color: "white",
  fontSize: "1.2rem",
  fontWeight: "bold",
  textTransform: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "2rem auto",
  cursor: disabled ? "default" : "pointer",
  "@media (max-width: 1024px)": {
    padding: "0.3rem 4rem",
    fontSize: "1rem",
    marginBottom: "4rem",  },
  "@media (max-width: 768px)": {
    padding: "0.5rem 3rem",
    fontSize: "0.9rem",
  },
}));


const AssistantSelection = () => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();

  const [avatar, setAvatar] = useState<string | null>(null);
  const [selectedAssistant, setSelectedAssistant] = useState<number | null>(null);
  const [selectedLanguages, setSelectedLanguages] = useState<{ [key: number]: string }>({});

  useEffect(() => {
    const storedAssistant = localStorage.getItem("selectedAssistant");
    const storedAvatar = localStorage.getItem("selectedAvatar");
  
    if (storedAssistant) {
      const assistantId = parseInt(storedAssistant, 10);
      const selected = assistants.find(a => a.id === assistantId);
      if (selected) {
        setSelectedAssistant(selected.id);
        setAvatar(selected.img);
      } else {
        setSelectedAssistant(assistants[0].id);
        setAvatar(assistants[0].img);
        localStorage.setItem("selectedAssistant", String(assistants[0].id));
        localStorage.setItem("selectedAvatar", assistants[0].img);
      }
    } else {
      setSelectedAssistant(assistants[0].id);
      setAvatar(assistants[0].img);
      localStorage.setItem("selectedAssistant", String(assistants[0].id));
      localStorage.setItem("selectedAvatar", assistants[0].img);
    }
  }, []);
  
  
  const handleSelect = (id: number) => {
    if (selectedAssistant === id) return;
  
    const selected = assistants.find(a => a.id === id);
    if (selected) {
      setSelectedAssistant(id);
      setAvatar(selected.img);
      localStorage.setItem("selectedAssistant", String(id));
      localStorage.setItem("selectedAvatar", selected.img);
    }
  };

  return (
    <Container>
      <CardContainer>
        {assistants.map((assistant) => (
          <Box key={assistant.id} style={{ position: "relative", paddingTop: "35px" }}>
            <HeartContainer onClick={() => handleSelect(assistant.id)}>
              <img
                src={selectedAssistant === assistant.id ? RedHeart : GreyHeart}
                alt="heart-icon"
                width="40px"
                height="40px"
                style={{ cursor: "pointer" }}
              />
            </HeartContainer>
  
            <AssistantCard onClick={() => handleSelect(assistant.id)} selected={selectedAssistant === assistant.id}>
              <AvatarWrapper>
                <AvatarImage src={assistant.img} alt={assistant.name} />
              </AvatarWrapper>
  
              <NameText>
                {assistant.name === "Hæfni" ? (
                  <>
                    <span style={{ color: "#002e5a" }}>Hæf</span>
                    <span style={{ color: "#D72638" }}>ni</span>
                  </>
                ) : assistant.name === "Kristján" ? (
                  <span style={{ color: "#002e5a" }}>{assistant.name}</span>
                ) : assistant.name === "Glódís" ? (
                  <>
                    <span style={{ color: "#002e5a" }}>Gló</span>
                    <span style={{ color: "#D72638" }}>dís</span>
                  </>
                ) : (
                  <Box display="flex" flexDirection="column">
                    <span style={{ color: "#D72638" }}>Corporate</span>
                    <span style={{ color: "#002e5a" }}>Lerncoach</span>
                  </Box>
                )}
              </NameText>
  
              <DescriptionText>{assistant.description[i18n.language as "en" | "de"]}</DescriptionText>

  
              <FormText>{assistant.form[i18n.language as "en" | "de"]}</FormText>
  
              <LanguageContainer>
                <LanguageIcons>
                  {languages.map((lang, index) => {
                    const isActive = assistant.activeLanguages.includes(lang.flag);
  
                    return (
                      <LanguageCircle key={index} active={isActive}>
                        <img
                          src={lang.flag}
                          alt="flag"
                          width="100%"
                          height="100%"
                          style={{
                            objectFit: "cover",
                            borderRadius: "50%",
                            filter: isActive ? "none" : "grayscale(100%)",
                            opacity: isActive ? "1" : "0.5",
                          }}
                        />
                      </LanguageCircle>
                    );
                  })}
                </LanguageIcons>
                <ComingSoonText>{t("Coming Soon")}</ComingSoonText>
              </LanguageContainer>
            </AssistantCard>
          </Box>
        ))}
      </CardContainer>

      <Typography
  variant="h6"
  sx={{
    textAlign: 'center',
    fontWeight: 700,
    fontSize: '2rem',
    marginTop: '4rem',
    marginBottom: '2rem',
    color: '#002E5A',
  }}
>
  {t("selectUseCaseDescription")}
</Typography>

      <Box display="flex" flexWrap="wrap" justifyContent="center" gap="3rem" mt={4}>
  {/* Aufgabenbereich */}
  <Box
    sx={{
      border: '5px solid #A0B29F',
      borderRadius: '15px',
      padding: '1rem',
      backgroundColor: '#F8F9F8',
      width: '300px',
      textAlign: 'center',
    }}
  >
    <img src="/grafik_aufgabenbereich.png" alt="Aufgabenbereich" style={{ width: '100%', borderRadius: '10px' }} />
    <Typography fontWeight="bold" fontSize="1.1rem" mt={2}>
      {t("Function Selection")}
    </Typography>
    <select style={{ width: '100%', marginTop: '0.5rem', padding: '0.5rem', fontSize: '1rem' }}>
      <option value="divers">Divers</option>
      <option value="vertrieb" style={{ color: 'gray' }}>Sales</option>
      <option value="marketing" style={{ color: 'gray' }}>Marketing</option>
      <option value="controlling" style={{ color: 'gray' }}>Accounting</option>
      <option value="hr" style={{ color: 'gray' }}>HR</option>
      <option value="einkauf" style={{ color: 'gray' }}>Procurement</option>
      <option value="it" style={{ color: 'gray' }}>IT</option>
    </select>
  </Box>

  {/* Branche */}
  <Box
    sx={{
      border: '5px solid #A0B29F',
      borderRadius: '15px',
      padding: '1rem',
      backgroundColor: '#F8F9F8',
      width: '300px',
      textAlign: 'center',
    }}
  >
    <img src="/grafik_branche.png" alt="Branche" style={{ width: '100%', borderRadius: '10px' }} />
    <Typography fontWeight="bold" fontSize="1.1rem" mt={2}>
      {t("Industry Selection")}
    </Typography>
    <select style={{ width: '100%', marginTop: '0.5rem', padding: '0.5rem', fontSize: '1rem' }}>
      <option value="software">Software/ICT</option>
      <option value="maschinenbau" style={{ color: 'gray' }}>Engineering</option>
      <option value="chemie" style={{ color: 'gray' }}>Chemistry/Pharma</option>
      <option value="banken" style={{ color: 'gray' }}>Banking/Insurance</option>
      <option value="logistik" style={{ color: 'gray' }}>Logistics</option>
      <option value="handel" style={{ color: 'gray' }}>Retail</option>
      <option value="energie" style={{ color: 'gray' }}>Energy</option>
    </select>
  </Box>
</Box>
  
      <SkillaryButton
        disabled={!selectedAssistant}
        onClick={() => navigate("/library")}
      >
        {t("goToSkillary")}
      </SkillaryButton>

    </Container>
  );
  


};

export default AssistantSelection;
