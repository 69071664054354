import React from "react";
import { Box, Grid, Card, Typography, styled, Container } from "@mui/material";
import SkillboardCourses from "ui/components/skillboard/SkillboardCourses";
import Leaderboard from "ui/components/skillboard/Leaderboard";
import SkillAchievements from "./SkillAchievements";
import StreakRanking from "./StreakRanking";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

const DashboardContainer = styled(Container)(({ theme }) => ({
  padding: "2rem",
  backgroundColor: theme.palette.background.default,
  minHeight: "100%",
  marginTop: "5rem"
}));
const SectionHeader = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main, // Signature Farbe aus Theme
  color: "#fff", // Weiße Schrift
  padding: "10px 15px",
  marginBottom: "0.8rem",
  borderRadius: "8px",
  fontWeight: "bold",
  fontSize: "1.2rem",
}));
 
const StyledCard = styled(Card)(({ theme }) => ({
  padding: "1.5rem",
  borderRadius: "12px",
  background: theme.palette.background.paper,
  boxShadow: "0px 6px 12px rgba(0,0,0,0.1)",
  transition: "transform 0.2s ease-in-out",
  "&:hover": {
    transform: "scale(1.02)",
  },
}));

const Skillboard = () => {
  return (
    <DashboardContainer maxWidth="lg"sx={{background:"#f7f7f7"}}>
      {/* Lernerfolge */}
      <SectionHeader>Lernerfolge</SectionHeader>
      <SkillAchievements />

      {/* Leaderboard & Streak Ranking */}
      <SectionHeader>Rankings</SectionHeader>
      <Box  sx={{ backgroundColor: "#e0e0e0", padding: 3, borderRadius: 3, marginBottom: "1.5rem"}}>
      <Grid container spacing={3} >
        <Grid item xs={12} md={6}>
          <Leaderboard />
        </Grid>
        <Grid item xs={12} md={6}>
          <StreakRanking />
        </Grid>
      </Grid>
      </Box>

      {/* Lernkurse */}
      <SectionHeader>Lernkurse</SectionHeader>
      <StyledCard>
      <SkillboardCourses />
      </StyledCard>
    </DashboardContainer>
  );
};

export default Skillboard;
