import React, { useEffect, useState } from "react";
import { Box, styled } from "@mui/material";
import { MobileWidth } from "entities/constants";
import useScreenSize from "hooks/ScreenSize";
import SkillsDesktop from "../home/SkillsDesktop";
import SkillsListView from "ui/components/mobile/skills/SkillsListView";
import { DrawerPropType, SkillsPropType } from "entities/interfaces";
import Header from "ui/components/shared/Header";
import WithSkillsData from "ui/containers/skills";

const SkillsWrapper = styled(Box)(() => ({
  height: "100%",
  width: "100%",
  display: "flex",
}));

const Skills = ({ courses, deleteUserCourse, updateProgress}: SkillsPropType) => {
  const { width } = useScreenSize()
  const [active, setActive] = useState<DrawerPropType>({
    title: "",
    thumbnail: "",
  });

  useEffect(() => {
    if (width > MobileWidth && !active.id) {
      const lastCourseId = localStorage.getItem("lastBookedCourse");
      const foundCourse = courses.find(course => String(course.id) === lastCourseId);
      
      if (foundCourse) {
        console.log(`Setze gespeicherten Kurs: ${foundCourse.title} (ID: ${foundCourse.id})`);
        setActive(foundCourse);
      } else if (courses.length > 0) {
        console.log("Kein gespeicherter Kurs gefunden, setze Standardkurs.");
        setActive(courses[0]);
      }
    }
  }, [courses, active.id, width]);

  return (
    <SkillsWrapper sx={{ flexDirection: 'column' }}>
      {width > MobileWidth ? (
        <>
          <Header data={active} />
          <SkillsDesktop options={courses} active={active} setActive={setActive} deleteUserCourse={deleteUserCourse} updateProgress={updateProgress}/>
        </>
      ) : (
        <SkillsListView options={courses} active={active} setActive={setActive} deleteUserCourse={deleteUserCourse} updateProgress={updateProgress}/>
      )}
    </SkillsWrapper>
  );
};

export default WithSkillsData(Skills);


