// components/ComingSoonDialog.tsx
import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

interface ComingSoonDialogProps {
  open: boolean;
  onClose: () => void;
}

const ComingSoonDialog: React.FC<ComingSoonDialogProps> = ({ open, onClose }) => {
  const {t} = useTranslation();

  return (
<Dialog
  open={open}
  onClose={onClose}
  hideBackdrop
  maxWidth="xs"
  fullWidth
  PaperProps={{
    style: {
      borderRadius: '1rem',
      padding: '1rem',
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)', 
    },
    elevation: 3,
  }}
>

      <DialogTitle sx={{ textAlign: 'center', fontWeight: 'bold' }}>
        {t("🚧 Page Under Construction 🚧")}
      </DialogTitle>
      <DialogContent>
        <Typography align="center">
        {t("This feature is not available yet. Please check back later to learn more!")}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button
          onClick={onClose}
          variant="contained"
          sx={{ backgroundColor: '#9CAF9F', fontWeight: 600 }}
        >
          {t("Back to Home")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ComingSoonDialog;
