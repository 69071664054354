import { Box, styled, Typography, TextField, MenuItem, Select, InputLabel, FormControl, SelectChangeEvent  } from '@mui/material';
import React, { useContext, useState, useEffect } from 'react';
import LibrarySkillCard from '../cards/LibrarySkillCard';
import BookSkillCard from '../cards/BookSkillCard';
import { SelectedSkillContext } from 'data/index';
import { LibraryPropType } from 'entities/interfaces';
import { useTranslation } from 'react-i18next';
import { POST } from 'api/index'; 

// Styled Components
const Container = styled(Box)(({ theme }) => ({
  height: '100%',
  padding: '2rem 1.5rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',
  backgroundColor: theme.palette.background.default,
  borderRadius: '1rem',
  boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '0.5rem',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '10px',
  },
}));

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '1.5rem',
  fontWeight: 600,
  color: theme.palette.text.primary,
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',
}));

const CoursesGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
  gap: '1.5rem',
}));

const FilterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '1rem',
  alignItems: 'center',
  backgroundColor: theme.palette.background.paper,
  padding: '1rem',
  borderRadius: '0.75rem',
  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  flex: 1,
  '.MuiOutlinedInput-root': {
    borderRadius: '0.75rem',
    backgroundColor: theme.palette.background.default,
  },
}));

const StyledSelect = styled(Select)(({ theme }) => ({
  minWidth: '12rem',
  '.MuiOutlinedInput-root': {
    borderRadius: '0.75rem',
    backgroundColor: theme.palette.background.default,
  },
}));

const Library = ({ courses }: LibraryPropType) => {
  const { state: selectedSkill } = useContext(SelectedSkillContext);
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState('');
  const [categoryFilter, setCategoryFilter] = useState('All');
  const token = window.localStorage.getItem("currentUserToken");
  const [categories, setCategories] = useState<{ id: string; category: string }[]>([]);
  const [courseCategories, setCourseCategories] = useState<{ [key: string]: string[] }>({}); 
  const [durations, setDurations] = useState<{ [key: number]: number }>({});

  

  const fetchCategories = async () => {
    try {
      const body = new URLSearchParams();
      if (token) {
        body.append("userToken", token);
      }

      const response = await POST("getCategories", body);
      if (response && response.result) {
        const uniqueCategories = response.result.map((cat: any) => ({
          id: cat.id.toString(),
          category: cat.category,
        }));
        setCategories(uniqueCategories);
      } else {
        console.warn("No categories:", response);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  const fetchCourseDurations = async () => {
    try {
      const response = await POST("getAllCourseDurations", new URLSearchParams());
  
      if (response?.result) {
        const durationMap: { [key: number]: number } = {};
  
        response.result.forEach((entry: any) => {
          durationMap[parseInt(entry.course_id)] = parseInt(entry.duration);
        });
  
        setDurations(durationMap);
        console.log(durationMap);
      }
    } catch (err) {
      console.error("Error fetching durations", err);
    }
  };
  

  const fetchCourseCategories = async () => {
    try {
      const body = new URLSearchParams();
      if (token) {
        body.append("userToken", token);
      }
  
      const response = await POST("getCourseCategories", body);
      if (response && response.result) {
        console.log("Response courseCategories",response)
        const categoryMap: { [key: string]: string[] } = {};
  
        response.result.forEach((entry: any) => {
          const { course_id, category_id } = entry;
          if (!categoryMap[course_id]) {
            categoryMap[course_id] = [];
          }
          categoryMap[course_id].push(category_id.toString());
        });
  
        setCourseCategories(categoryMap);
      } else {
        console.warn("No categories fetched:", response);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
    }
  };
  
  useEffect(() => {
    fetchCategories();
    fetchCourseCategories();
  }, []);
  
  useEffect(() => {
    if (courses.length > 0) {
      fetchCourseDurations();
    }
  }, [courses]);
  

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategoryFilter(event.target.value as string);
  };

  const filteredCourses = courses.filter((course) => {
    const matchesSearch = course.title.toLowerCase().includes(searchQuery.toLowerCase());
    const courseCategoryList = courseCategories[course.id] || [];
    const matchesCategory =
      categoryFilter === "All" || courseCategoryList.includes(categoryFilter);
  
    return matchesSearch && matchesCategory;
  });
  
  
  return (
    <Container>
      {/* Header Section */}
      <Header>
        <Title>{t('Explore Skills and Courses')}</Title>
      </Header>

      {/* Filter Section */}
      <FilterContainer>
        <StyledTextField
          label={t('Search')}
          variant="outlined"
          value={searchQuery}
          onChange={handleSearchChange}
        />
<FormControl variant="outlined">
  <InputLabel>{t('Category')}</InputLabel>
  <StyledSelect
    value={categories.length > 0 ? categoryFilter : "All"} 
    onChange={(event) => handleCategoryChange(event as SelectChangeEvent<string>)}
    label={t('Category')}
  >
    <MenuItem value="All">{t('All')}</MenuItem>
{
    categories.map((category) => (
      <MenuItem key={category.id} value={category.id}>
        {t(`categories.${category.category}`, category.category)}
      </MenuItem>
    ))}
  </StyledSelect>
</FormControl>


      </FilterContainer>

      {/* Main Content Section */}
      <ContentWrapper>
        {/* Courses Section */}
        {courses.length === 0 ? null : filteredCourses.length > 0 ? (
  <Box>
    <Typography variant="h6" color="textSecondary">
      {t('All Courses')}
    </Typography>
    <CoursesGrid>
      {filteredCourses.map((course, index) => (
        <LibrarySkillCard
          key={index}
          data={{ ...course, duration: durations[course.id],ratings: 5, ratingsCount: 100 }}
        />
      ))}
    </CoursesGrid>
  </Box>
) : searchQuery || categoryFilter !== 'All' ? (
  <Typography variant="body1" color="textSecondary">
    {t('No courses found.')}
  </Typography>
) : null}


        {/* Selected Skill Section */}
        {selectedSkill && (
          <Box>
            <Typography variant="h6" color="textSecondary">
              {t('Your Selected Skill')}
            </Typography>
            <BookSkillCard />
          </Box>
        )}
      </ContentWrapper>
    </Container>
  );
};

export default Library;
