import React, { useEffect, useState } from "react";
import { Box, Typography, Grid, Paper, Tooltip, Stack } from "@mui/material";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import StarIcon from "@mui/icons-material/Star";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import { POST } from "api/index";
import { usePoints } from "context/PointsContext";
import ScoreIcon from 'assets/icons/scoreIcon.png' 
import FlameIcon from 'assets/icons/flame.png'


interface StatCardProps {
  title: string;
  value: string | number;
  icon: React.ReactElement;
  color: string;
  subtitle?: string;
}



// Modernes Card-Design mit MUI Paper
const StatCard : React.FC<StatCardProps> = ({ title, value, icon, color, subtitle }) =>(
  <Paper elevation={3} sx={{ padding: 3, textAlign: "center", borderRadius: 3, height: "100%", width:"100%",minWidth: "50px" }}>
    <Tooltip title={title}>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 1, marginBottom: "0.5rem" }}>
        {React.cloneElement(icon, { sx: { fontSize: 40, color } })}
      </Box>
    </Tooltip>
    <Typography variant="h6" fontWeight="bold">
      {title}
    </Typography>
    <Typography variant="h4" fontWeight="bold" color={color}>
      {value}
    </Typography>
    <Typography variant="body2">{subtitle}</Typography>
  </Paper>
);

const SkillAchievements: React.FC = () => {
  const token = window.localStorage.getItem("currentUserToken");
  const { totalPoints } = usePoints();
  const [streak, setStreak] = useState<number>(0);
  const [weeklyPoints, setWeeklyPoints] = useState<number>(0);

  useEffect(() => {
    const getUserStreak = async () => {
      const body = new URLSearchParams();
      body.append("userToken", token || "");
      const response = await POST("getUserStreak", body);
      if (response.status === 200) setStreak(response.streak);
    };
    getUserStreak();
  }, []);

  useEffect(() => {
    const getWeeklyPoints = async () => {
      const body = new URLSearchParams();
      body.append("userToken", token || "");
      const response = await POST("getWeeklyUserPoints", body);
      if (response.status === 200) {
        setWeeklyPoints(response.weekly_points.total_course_points+response.weekly_points.total_interactive_points);
      }
    };
    getWeeklyPoints();
  }, []);

  return (
    <Box sx={{ backgroundColor: "#e0e0e0", padding: 3, borderRadius: 3, marginBottom: "1.5rem"}}>
      <Grid container spacing={3} justifyContent="center" alignItems="stretch" sx={{ display: "flex", flexWrap: "wrap" }}>
        {/* Cards mit modernem Design */}
        <Grid item xs={12} sm={6} md={2.4}sx={{ flex: "1 1 auto", maxWidth: "250px" }}>
          <StatCard title="Auszeichnungen" value="👑" icon={<EmojiEventsIcon />} color="#FFD700" subtitle="Konflikte lösen" />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}sx={{ flex: "1 1 auto", maxWidth: "250px"   }}>
          <StatCard title="Learning Points" value={totalPoints} icon={<img src={ScoreIcon} alt="Coin" width={40} />} color="#FFC107" />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}sx={{ flex: "1 1 auto", maxWidth: "250px" }}>
          <StatCard title="Level" value="1" icon={<TrendingUpIcon />} color="#2E7D32" subtitle="98 LPs bis Level 2" />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}sx={{ flex: "1 1 auto", maxWidth: "250px"   }}>
          <StatCard title="Streak" value={streak} icon={<img src={FlameIcon} alt="Coin" width={40} />} color="#FF5722" subtitle="Tägliche Aktivität" />
        </Grid>
        <Grid item xs={12} sm={6} md={2.4}sx={{ flex: "1 1 auto", maxWidth: "250px" }}>
          <StatCard
            title="Punkte seit letzter Woche"
            value={weeklyPoints > 0 ? `+${weeklyPoints}` : weeklyPoints}
            icon={<TrendingUpIcon />}
            color={weeklyPoints > 0 ? "#4CAF50" : "#FF5722"}
            subtitle="Dein Fortschritt"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SkillAchievements;
