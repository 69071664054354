import React, { useContext } from 'react';
import { Box, Button, IconButton, Rating, Typography, alpha, styled } from '@mui/material';
import { SelectedSkillContext, TextSizeContext } from 'data/index';
import ImageComponent from 'ui/components/shared/ImageComponent';
import CloseIcon from '@mui/icons-material/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { useTranslation } from 'react-i18next';
import { MobileWidth, TextSizes } from 'entities/constants';
import WithBookSkillData from 'ui/containers/library/BookSkillContainer';
import { BookingCardPropType } from 'entities/interfaces';
import useScreenSize from 'hooks/ScreenSize';
import { useNavigate } from 'react-router-dom';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { useTheme } from '@mui/material';

// -- Styles --

const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? alpha(theme.palette.common.black, 0.1) : alpha(theme.palette.common.white, 0.6),
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  height: '100vh',
  zIndex: 9999,
  display: 'flex',
  justifyContent: 'flex-end',
}));

const PopUp = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.common.white : '#fff',
  width: '33.125rem',
  maxWidth: '100%',
  height: '100%',
  borderRadius: '0.75rem 0px 0px 0.75rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const HeaderBox = styled(Box)(({ theme }) => ({
  height: '5rem',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  gap: '0.75rem',
}));

const SkillBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  flexGrow: '1',
  backgroundColor: theme.palette.grey[300],
  gap: '1rem',
  overflowY: 'hidden',
}));

const ScrollBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  flexGrow: '1',
  padding: '0rem 2rem 0rem 2rem',
  gap: '1rem',
  overflowY: 'scroll',
  "&::-webkit-scrollbar": {
    width: "0.4rem",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.primary.main,
    borderRadius: "10px",
  },
}));

const WhiteText = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: '1rem',
  margin: '1rem',
}));

const ActionButton = styled(Button)(({ theme }) => ({
  width: '8rem',
  borderRadius: '3.5rem',
  textTransform: 'none',
  fontWeight: 700,
}));
const DurationBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.4rem',
  fontWeight: 600,
  paddingLeft: '0.3rem',
}));


// -- Komponente --

const BookSkillCard = ({ bookCourse }: BookingCardPropType) => {
  const { t } = useTranslation();
  const { state: selectedSkill, updateContext: setSelectedSkill } = useContext(SelectedSkillContext);
  const { state: textSize } = useContext(TextSizeContext);
  const { width } = useScreenSize();
  const theme = useTheme();
  const navigate = useNavigate();

  // Beim Klick auf "Jetzt starten"
  const handleBooking = async () => {
    if (selectedSkill) {
      try {
        console.log(`Buchung startet für Kurs-ID: ${selectedSkill.id}`);
        const resp = await bookCourse(selectedSkill.id);

        if (resp.status === 200) {
          console.log(`Buchung erfolgreich! Speichere Kurs-ID: ${selectedSkill.id}`);

          localStorage.setItem("lastBookedCourse", String(selectedSkill.id));

          navigate('/myskills');

          setSelectedSkill(undefined);
        } else {
          console.error("Fehler beim Buchen:", resp);
        }
      } catch (err) {
        console.error("Netzwerk-/Serverfehler beim Buchen:", err);
      }
    }
  };



  const handleBookLater = async () => {
    if (selectedSkill) {
      try {
        console.log(`Speichere Kurs für später: ${selectedSkill.id}`);
        const resp = await bookCourse(selectedSkill.id);

        if (resp.status === 200) {
          console.log(`Kurs-ID ${selectedSkill.id} erfolgreich gespeichert!`);
        } else {
          console.error("Fehler beim Speichern des Kurses für später:", resp);
        }
      } catch (err) {
        console.error("Netzwerk-/Serverfehler beim Speichern:", err);
      }
    }
    setSelectedSkill(undefined);
  };

  if (!selectedSkill) return null;
  const isComingSoon = Number(selectedSkill.status) === 2;
  console.log(isComingSoon)

  return (
    <Wrapper>
      {/* Klick neben Popup schließt es */}
      <Box onClick={() => setSelectedSkill(undefined)} sx={{ flexGrow: '1', height: '100%' }} />
      <PopUp>
        <HeaderBox
          padding={`0.25rem ${width <= MobileWidth ? 2 : 1.25}rem`}
          flexDirection={width <= MobileWidth ? 'row-reverse' : 'row'}
          justifyContent={width <= MobileWidth ? 'space-between' : 'flex-start'}
        >
          {/* Schwarzes "X" zum Schließen */}
          <IconButton onClick={() => setSelectedSkill(undefined)}>
            <CloseIcon fontSize="large" sx={{ color: 'black' }} />
          </IconButton>
          <WhiteText fontSize={TextSizes[textSize].title3} fontWeight={700}>
            {t('Your Learning Takeaways')}
          </WhiteText>
        </HeaderBox>

        {/* Kursbild */}
        <ImageComponent
          src={selectedSkill.image}
          alt="skill image"
          width="100%"
          height={width <= MobileWidth ? '13rem' : '18.75rem'}
          objectFit
        />

        <SkillBox>
        <Box sx={{ padding: '2rem 2rem 0rem 2rem' }}>
  <WhiteText fontSize={TextSizes[textSize].title1} fontWeight={700}>
    {selectedSkill.title}
  </WhiteText>
  <WhiteText fontSize={TextSizes[textSize].subhead}>
    {selectedSkill.subtitle}
  </WhiteText>

  {/* Bewertung + Dauer */}
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '0.5rem',
      width: '100%',
    }}
  >
    {/* Bewertung */}
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
      <Rating
        name="half-rating"
        value={selectedSkill.ratings}
        precision={0.1}
        readOnly
      />
      <WhiteText fontSize={TextSizes[textSize].title3} fontWeight={700}>
        {selectedSkill.ratings.toFixed(1)}
      </WhiteText>
      <WhiteText fontSize={TextSizes[textSize].footnote}>
        ({selectedSkill.ratingsCount.toLocaleString().replace(',', '.')})
      </WhiteText>
    </Box>

    {/* Dauer */}
    {typeof selectedSkill.duration === 'number' && selectedSkill.duration > 0 && (
      <DurationBox>
        <HourglassBottomIcon sx={{ fontSize: '1.5rem', color: theme.palette.primary.main }} />
        <WhiteText fontSize={TextSizes[textSize].title3} fontWeight={600}>
          {selectedSkill.duration} min
        </WhiteText>
      </DurationBox>
    )}
  </Box>
</Box>


          {/* Kursbeschreibung */}
          <ScrollBox>
            <WhiteText fontSize={TextSizes[textSize].subhead}>
              {decodeURIComponent(selectedSkill.description)}
            </WhiteText>

            {/* Kapitelübersicht */}
            <Box>
              <WhiteText fontSize={TextSizes[textSize].title3} fontWeight={700}>
                {t('Chapter overview')}:
              </WhiteText>
              <Box sx={{ gap: '0.25rem', display: 'flex', flexDirection: 'column' }}>
                {selectedSkill.chapters.map((name, index) => (
                  <WhiteText key={index} fontSize={TextSizes[textSize].subhead}>
                    {index + 1}. {name}
                  </WhiteText>
                ))}
              </Box>
            </Box>
          </ScrollBox>

          {/* Buttons */}
          {!isComingSoon && (
          <ButtonContainer>
            <ActionButton
              variant="contained"
              color="primary"
              onClick={handleBooking}
              startIcon={<PlayArrowIcon sx={{ width: "2rem", height: "2rem" }} />}
              sx={{
                borderRadius: '2rem',
                minWidth: "170px",
                height: "3rem",
                fontSize: '1rem',
                fontWeight: 'bold',
                padding: "0.75rem 1.5rem",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '0rem',
              }}
            >
              {t('Start Now')}
            </ActionButton>

            <ActionButton
              variant="outlined"
              color="secondary"
              onClick={handleBookLater}
              startIcon={<BookmarkBorderIcon sx={{ width: "2rem", height: "2rem" }} />}
              sx={{
                borderRadius: '2rem',
                minWidth: "170px",
                height: "3rem",
                fontSize: '1rem',
                fontWeight: 'bold',
                padding: "0.75rem 1.5rem",
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '0rem',
              }}
            >
              {t('Later')}
            </ActionButton>
          </ButtonContainer>
          )}
{isComingSoon && (
  <Box sx={{ padding: "1rem", textAlign: "center" }}>
    <WhiteText fontSize="1rem" fontWeight={600}>
      {t("This course will be available soon.")}
    </WhiteText>
  </Box>
)}

        </SkillBox>
      </PopUp>
    </Wrapper>
  );
};

export default WithBookSkillData(BookSkillCard);
