import React, { useContext, useState } from "react";
import { Box, Button, Typography, styled, useTheme } from "@mui/material";
import { DarkModes, TextModes } from "entities/constants";
import RadioButtons from "ui/components/shared/RadioButtons";
import TitleBox from "ui/components/shared/TitleBox";
import { BackgroundContext, DarkModeContext, TextSizeContext } from "data/index";
import ImageComponent from "ui/components/shared/ImageComponent";
import BackgroundImage2 from "assets/images/Background_Chat.png";
import BackgroundImage1 from "assets/images/Background.png";

// **Styled Components**
const WrapperBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start", // 🔹 Alles linksbündig
  width: "100%",
  height: "100%",
  padding: "1.4rem 0.8rem",
}));

const BackgroundBox = styled(Box)(() => ({
  display: "flex",
  gap: "1rem",
  opacity: 0.5, // 🔹 Ausgrauen
  pointerEvents: "none", // 🔹 Deaktivieren der Buttons
}));

const BackgroundButton = styled(Button)<{ border: string; backgroundColor?: string }>(
  ({ theme, border, backgroundColor }) => ({
    width: "5.375rem",
    height: "5.375rem",
    padding: 0,
    borderRadius: "0.5rem",
    border: border,
    boxShadow: "0px 2px 4px 0px rgba(121, 121, 121, 0.25)",
    backgroundColor: backgroundColor ? backgroundColor : theme.palette.common.white,
    overflow: "hidden",
  })
);

const StyledTitle = styled(Typography)({
  fontSize: "2rem",
  fontWeight: "bold",
  color: "#002e5a",
  textAlign: "center", // 🔥 Jetzt mittig zentriert
  marginBottom: "1rem",
  marginTop: "10rem",
  width: "100%", // 🔥 Sicherstellen, dass es über die gesamte Breite geht
});

const BackgroundComponent = () => {
  const { state: backgroundImage } = useContext(BackgroundContext);
  const theme = useTheme();

  const getBorder = (num: number) => (backgroundImage === num ? `0.125rem solid ${theme.palette.primary.main}` : "");

  return (
    <BackgroundBox>
      <BackgroundButton border={getBorder(1)} color="primary">
        <ImageComponent
          src={BackgroundImage1}
          alt="background1"
          width="100%"
          height="100%"
          borderRadius="0.5rem"
          transform="scale(4)"
        />
      </BackgroundButton>
      <BackgroundButton border={getBorder(2)} color="primary">
        <ImageComponent
          src={BackgroundImage2}
          alt="background2"
          width="100%"
          height="100%"
          borderRadius="0.5rem"
          transform="scale(4)"
        />
      </BackgroundButton>
      <BackgroundButton border={getBorder(3)} backgroundColor={theme.palette.secondary.main} color="primary" />
      <BackgroundButton border={getBorder(4)} color="primary" />
    </BackgroundBox>
  );
};

const LearningPreferenceTab = () => {
  const { state: darkTheme } = useContext(DarkModeContext);
  const { state: textSize, updateContext: setTextSize } = useContext(TextSizeContext);

  const [textMode, setTextMode] = useState(1);
  const [darkMode, setDarkMode] = useState(darkTheme ? 0 : 1);

  const handleTextModeChange = (event: any) => {
    setTextMode(event.target.value);
  };

  const handleTextSizeChange = (event: any) => {
    var value = event.target.value;
    setTextSize(value);
    localStorage.setItem("textSize", value.toString());
  };

  return (
    <WrapperBox>
      {/* ✅ Text Mode Auswahl */}
      <TitleBox title="Text Mode">
        <RadioButtons options={TextModes} onChange={handleTextModeChange} value={textMode} />
      </TitleBox>

      {/* ✅ Textgröße Auswahl */}
      <TitleBox title="Text Size">
        <RadioButtons options={["Small", "Medium", "Large"]} onChange={handleTextSizeChange} value={textSize} />
      </TitleBox>

      {/* ✅ Dark Mode (ausgegraut) */}
      <Box sx={{ opacity: 0.5, pointerEvents: "none" }}>
        <TitleBox title="Dark Mode">
          <RadioButtons options={DarkModes} value={darkMode} />
        </TitleBox>
      </Box>

      {/* ✅ Hintergrundbild Auswahl (ausgegraut) */}
      <Box sx={{ opacity: 0.5, pointerEvents: "none" }}>
        <TitleBox title="Background">
          <BackgroundComponent />
        </TitleBox>
      </Box>

      {/* ✅ Assistenten Auswahl */}
    </WrapperBox>
  );
};

export default LearningPreferenceTab;
