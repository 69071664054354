import { Accordion, AccordionSummary, AccordionDetails, Typography, Box } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState, useEffect } from "react";
import { POST } from "api/index";

const LearningAccordion = ({ chapter }: { chapter: any }) => {
  const [learningCount, setLearningCount] = useState<number | null>(null);

  // API Call nur für gesperrte Kapitel ausführen
  useEffect(() => {
    const fetchLearningCount = async () => {
      if (!chapter.locked) return; // Nur für gesperrte Kapitel
      try {
        const body = new URLSearchParams();
        body.append("chapter_id", chapter.id.toString());
  
        const response = await POST("getSkillboxLearningCount", body);
        if (response && typeof response.learning_count !== "undefined") {
          console.log(`🔢 Learning Count für Kapitel ${chapter.id}:`, response.learning_count);
          setLearningCount(response.learning_count);
        } else {
          setLearningCount(0);
        }
      } catch (error) {
        console.error("Fehler beim Abrufen der Learnings:", error);
        setLearningCount(0);
      }
    };

    fetchLearningCount();
  }, [chapter.id, chapter.locked]);

  return (
    <Accordion
      key={chapter.id}
      disabled={chapter.locked}
      sx={{
        width: "100%",
        minWidth: "720px",
        backgroundColor: chapter.locked ? "#f0f0f0" : "#ffffff",
        border: "1px solid #ddd",
        borderRadius: "8px",
        boxShadow: 1,
        marginBottom: "10px",
      }}
    >
      {/* 🔼 Header */}
      <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ marginLeft: "1.5rem", minHeight: "56px"}} />}>
        <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          {/* Titel links */}
          <Typography fontWeight="bold" fontSize="1.1rem">
            {chapter.locked ? "🔒 " : ""} {chapter.title}
          </Typography>

          {/* Anzahl der Learnings rechts */}
          <Typography fontSize="1rem" color="textSecondary">
            {chapter.locked ? (learningCount !== null ? learningCount : "...") : chapter.learnings?.length || 0} Learnings
          </Typography>
        </Box>
      </AccordionSummary>

      {/* 📖 Inhalt */}
      <AccordionDetails>
        {chapter.locked ? (
          <Typography color="text.secondary">
            Dieses Kapitel ist gesperrt. Schließe vorherige Kapitel ab, um es zu entsperren.
          </Typography>
        ) : chapter.learnings.length > 0 ? (
          <Box
            sx={{
              backgroundColor: "#f9f9f9",
              padding: "15px",
              borderRadius: "8px",
              boxShadow: 1,
            }}
          >
            <ul style={{ paddingLeft: "20px", lineHeight: "1.6", listStyleType: "square" }}>
              {chapter.learnings.map((learning: any, index: number) => (
                <li key={index} style={{ marginBottom: "8px", fontSize: "1.05rem" }}>
                  {learning.content}
                </li>
              ))}
            </ul>
          </Box>
        ) : (
          <Typography color="text.secondary">Keine Learnings gefunden.</Typography>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default LearningAccordion;
