import { Box, Typography, styled } from "@mui/material";
import AssistantSelection from "ui/components/pages/settings/cards/AssistantCard";
import { useTranslation } from "react-i18next";

const PageContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "flex-start",
  width: "100%",
  minHeight: "100vh",
  padding: "4rem 2rem",

  "@media (max-width: 1024px)": {
    paddingTop: "3rem",
  },

  "@media (max-width: 768px)": {
    paddingTop: "2rem",
  },
});


const Title = styled(Typography)({
  marginTop: "4rem",
  fontSize: "3.5rem",
  fontWeight: "bold",
  color: "#002e5a",
  textAlign: "center",
  marginBottom: "2rem",
});

const AvatarSelectionPage = () => {
  const { t } = useTranslation();
  return (
    <PageContainer>
      <Title>{t("Avatar & Praxisfokus")}</Title>
      <AssistantSelection />
    </PageContainer>
  );
};

export default AvatarSelectionPage;
