import React, { useState, useEffect } from "react";
import { Box, Typography, Avatar, Paper, CircularProgress, Tooltip } from "@mui/material";
import ScoreIcon from "assets/icons/scoreIcon.png"; 
import { POST } from "api/index";
import { useTheme } from "@mui/material/styles";

interface User {
  username: string;
  total_points: number;
}
interface WrapperProps {
  children: React.ReactNode;
}
// 🏆 Styled Leaderboard Card
const LeaderboardWrapper: React.FC<WrapperProps> = ({ children }) =>  (
  <Paper elevation={3} sx={{ padding: "1.5rem", borderRadius: "12px", minWidth: "100%" }}>
    {children}
  </Paper>
);

const Leaderboard: React.FC = () => {
  const theme = useTheme();
  const [topUsers, setTopUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const token = window.localStorage.getItem("currentUserToken");

  useEffect(() => {
    const fetchLeaderboard = async () => {
      try {
        const leaderboardBody = new URLSearchParams();
        leaderboardBody.append("userToken", token || "");

        const response = await POST("getTopUsers", leaderboardBody);
        if (response.status === 200) {
          setTopUsers(response.top_users);
        } else {
          console.error("Fehler beim Laden des Leaderboards:", response);
        }
      } catch (error) {
        console.error("API-Fehler:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchLeaderboard();
  }, []);
  const formatUsername = (username: string) => {
    if (username.includes("_")) {
      const [firstName, lastName] = username.split("_");
      return `${firstName} ${lastName.charAt(0)}.`; 
    }
    return username;
  };

  return (
    <LeaderboardWrapper>
      <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
        🏆 Leaderboard
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : (
        topUsers.map((user, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0.8rem 1rem",
              borderRadius: "8px",
              backgroundColor: index === 0 ? "#EEC643" : index === 1 ? "#B4B4B4" : index === 2 ? "#AD8A56" : theme.palette.grey[700],
              mb: 1,
              transition: "transform 0.2s ease-in-out",
              "&:hover": { transform: "scale(1.02)" },
            }}
          >
            {/* Rang, Avatar & Name */}
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px", flexGrow: 1 }}>
              <Avatar src={""} sx={{ width: 40, height: 40 }} />
              <Typography variant="body1" fontWeight="bold">
                {index + 1}. {formatUsername(user.username)}
              </Typography>
            </Box>

            {/* Punkte & Icon */}
            <Tooltip title="Anzahl erreichter Punkte">
              <Box sx={{ display: "flex", alignItems: "center", gap: "5px", minWidth: "80px", justifyContent: "flex-end" }}>
                <Typography variant="body1" fontWeight="bold">{user.total_points}</Typography>
                <img src={ScoreIcon} alt="Score Icon" style={{ width: 24, height: 24 }} />
              </Box>
            </Tooltip>
          </Box>
        ))
      )}
    </LeaderboardWrapper>
  );
};

export default Leaderboard;
