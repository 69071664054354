import { POST } from 'api/index';
import { SkillsPropType, SkillCourseInterface } from 'entities/interfaces'
import useAlert from 'hooks/AlertHook';
import React, { useEffect, useState } from 'react'
import {Box, Typography, Button, styled} from '@mui/material'
import { useNavigate } from 'react-router-dom';
import Header from 'ui/components/shared/Header';
import { useTranslation } from "react-i18next";


const Wrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    textAlign: "center",
    backgroundColor: theme.palette.background.default,
  }));
  
  const MessageBox = styled(Box)(({ theme }) => ({
    background: theme.palette.mode === "light" ? "#ffffff" : theme.palette.grey[800],
    padding: "2rem 3rem",
    borderRadius: "12px",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  }));
  
  const BackButton = styled(Button)(({ theme }) => ({
      marginTop: "1.5rem",
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      padding: "0.8rem 2rem",
      fontSize: "1rem",
      fontWeight: "bold",
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
    }));


const WithSkillsData = (SkillsPage: React.FC<SkillsPropType>) => function WithProps() {
    const { setAlert } = useAlert()
    const token = window.localStorage.getItem("currentUserToken")
    const [courses, setCourses] = useState<SkillCourseInterface[]>([]);
    const [showEmpty, setShowEmpty] = useState(false);
    const {t} = useTranslation();


useEffect(() => {
  const timeout = setTimeout(() => {
    if (courses.length === 0) {
      setShowEmpty(true);
    }
  }, 1000); 

  return () => clearTimeout(timeout);
}, [courses]);


    const navigate = useNavigate()
    useEffect(() => {
        getCourses()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCourses = async () => {
        const body = new URLSearchParams();
        if (token)
            body.append('userToken', token);
        body.append('option', '1');
        const response = await POST('getCourses', body);

        const result: SkillCourseInterface[] = response.result;
        result.sort((a: any, b: any) => parseInt(a.ordering) - parseInt(b.ordering))

        const progressResult = await Promise.all(result.map(async (course) => {
            const body = new URLSearchParams();
            body.append('userToken', token || '');
            body.append('option', course.id.toString());
            const { result:chapters } = await POST('getChapters', body) || {result:[]};

            const chapterWiseProgress = await Promise.all(
                chapters.map(async (chapter: any) => {
                    const progressBody = new URLSearchParams();
                    progressBody.append('userToken', token || '');
                    progressBody.append('option', chapter.id.toString());
                    progressBody.append('content', '2');
                    const { result: progress } = await POST('getUserProgress', progressBody);
    
                    return parseInt(progress)
                })
            );

            var progress = 0
            chapterWiseProgress.forEach(chapterProgress => {
                if(chapterProgress === 2){
                    progress++
                }
            });

            return progress * 100 /chapters.length
        }));

        result.forEach((course, index) => {
            course.progress = progressResult[index];
        });

        setCourses(result)
    }

    const deleteUserCourse = async (courseId: number) => {
        const body = new URLSearchParams();
        if (token)
            body.append('userToken', token);
        body.append('content', courseId.toString());
        body.append('option', '0');
        const response = await POST('setUserCourseState', body);
        if (response.status === 200) {
            setAlert({ color: 'success', message: 'Course removed!' })
            getCourses()
        }
        else
            setAlert({ color: 'error', message: response.error })
        return response.status
    }

    const updateProgress = () => {
        // getCourses()
    }

    if (showEmpty && courses.length === 0){
        return(
            <>
            <Header />
            <Wrapper>
              <MessageBox>
  <Typography variant="h5" fontWeight="bold" mb={2}>
  {t("🎯 Start your first learning course!")}
  </Typography>
  <Typography variant="body1" mb={3}>
   {t("You haven't booked any courses yet. Check out the Skillary to find exciting ones!")}
  </Typography>
  <BackButton onClick={() => navigate("/Library")}>{t("Go to Skillary")}</BackButton>
  </MessageBox>
  </Wrapper>
  </>

        )
    }
    return (
        <SkillsPage courses={courses} deleteUserCourse={deleteUserCourse} updateProgress={updateProgress}/>
    )
}

export default WithSkillsData