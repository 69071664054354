import React, { useState, useEffect } from "react";
import { Box, Typography, Paper, LinearProgress, Button, CircularProgress, Tooltip, styled, Divider, Avatar } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import { useTheme } from "@mui/material/styles";
import { POST } from "api/index";
import ImageComponent from "../shared/ImageComponent";
import ComingSoonDialog from "../shared/ComingSoonDialog";

interface Course {
  name: string;
  id: number;
  progress: number;
  points: string;
  icon: string; 
}

// 📚 Styled Komponenten
const CourseCard = styled(Paper)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "1rem", // Abstand zwischen Icon & Inhalt
  padding: "1rem",
  borderRadius: "12px",
  background: theme.palette.background.paper,
  transition: "transform 0.2s ease-in-out",
  marginBottom: "1.4rem",
  "&:hover": { transform: "scale(1.02)" },
}));

const ProgressBar = styled(LinearProgress)(({ theme }) => ({
  flexGrow: 1,
  height: 14, // 🔹 Höherer Fortschrittsbalken
  borderRadius: 7, // Abgerundete Ecken
  backgroundColor: theme.palette.grey[100],
}));

const CourseButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  color: theme.palette.common.white,
  fontWeight: "bold",
  borderRadius: "4px",
  fontSize: "0.75rem",
  padding: "5px 10px",
  minWidth: "auto",
  height: "30px",
  "&:hover": { backgroundColor: theme.palette.error.dark },
}));

const TitleBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  padding: "1rem 1.5rem",
  borderRadius: "8px",
  textAlign: "center",
  marginBottom: "1rem",
}));

const SkillboardCourses: React.FC = () => {
  const theme = useTheme();
  const [courses, setCourses] = useState<Course[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [open, setOpen] = useState(false);
  const token = window.localStorage.getItem("currentUserToken");

  useEffect(() => {
    getCourses();
  }, []);


  const getCourses = async () => {
    try {
      const body = new URLSearchParams();
      if (token) body.append("userToken", token);
      const response = await POST("getCourses", body);

      if (response.status === 200) {
        
        const formattedCourses = response.result.map((course: any) => ({
          
          name: course.title,
          id: course.id,
          progress: 0, // Wird später aktualisiert
          points: "0/0",
          icon:  course.image ? course.image : "default-course-icon.png", 
        }));

        setCourses(formattedCourses);

        // Punkte abrufen
        const pointsRequests = formattedCourses.map(async (course: Course) => {
          const pointsBody = new URLSearchParams();
          if (token) pointsBody.append("userToken", token);
          pointsBody.append("courseId", course.id.toString());

          const pointsResponse = await POST("getCoursePoints", pointsBody);
          const userPointsResponse = await POST("getUserCoursePoints", pointsBody);


          return {
            id: course.id,
            maxPoints: pointsResponse?.total_points || 100,
            userPoints: userPointsResponse?.course_progress.progress || 75,
          };
        });

        // Ergebnisse sammeln
        const pointsResults = await Promise.all(pointsRequests);
        setCourses((prevCourses) =>
          prevCourses.map((course) => {
            const pointsData = pointsResults.find((p) => p.id === course.id);
            return pointsData
              ? {
                  ...course,
                  progress: (pointsData.userPoints / pointsData.maxPoints) * 100,
                  points: `${pointsData.userPoints}/${pointsData.maxPoints}`,
                }
              : course;
          })
        );
      } else {
        console.error("🚨 Fehler beim Abrufen der Kurse:", response);
      }
    } catch (error) {
      console.error("❌ API-Fehler:", error);
    } finally {
      setLoading(false);
    }
  };

  // 🎨 Fortschrittsfarben nach Fortschrittswert
  const getProgressColor = (progress: number) => {
    if (progress >= 75) return theme.palette.success.main; // 🟢 Grün für 81-100%
    if (progress > 25) return "#FFC107"; 
    return theme.palette.error.main;
  };
  

  return (
    <Box>

      {loading ? (
        <CircularProgress />
      ) : (
        courses.map((course, index) => (
          <Box key={index}>
            <CourseCard elevation={2}>
              {/* 🔹 Neu: Kurs-Icon als Avatar */}
              {course.icon ? (
                <ImageComponent src={course.icon} alt={course.name} width="60px" height="60px" />
              ) : (
                <Avatar src="default-course-icon.png" sx={{ width: 50, height: 50 }} />
              )}

              {/* Kursname & Fortschritt */}
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="h6" sx={{fontSize: "1.1rem", fontWeight: "bold"}}>{course.name}</Typography>
                <ProgressBar
                  variant="determinate"
                  value={course.progress}
                  sx={{
                    "& .MuiLinearProgress-bar": {
                      backgroundColor: getProgressColor(course.progress),
                    },
                    transition: "width 0.5s ease-in-out",
                  }}
                />
              </Box>

              {/* Punkte */}
              <Box sx={{ minWidth: "80px", textAlign: "center" }}>
                <Typography variant="body1" fontWeight="bold">
                  {course.points}
                </Typography>
              </Box>

              {/* Stern-Icon */}
              <Tooltip title="Punkte für diesen Kurs">
                <StarIcon sx={{ color: "#FFD700", marginX: "0.5rem" }} />
              </Tooltip>

              {/* Button */}
              <Box>
                <CourseButton onClick={() => setOpen(true)} size="small">Skillcards fortsetzen</CourseButton>
                <ComingSoonDialog open={open} onClose={() => setOpen(false)} />
              </Box>
            </CourseCard>

          </Box>
        ))
      )}
    </Box>
  );
};

export default SkillboardCourses;