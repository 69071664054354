import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useDrag, useDrop } from 'react-dnd/dist';
import { useTheme, Divider } from '@mui/material';
import { Card, CardContent, Typography } from '@mui/material';
import { Paper } from '@mui/material';
import { Box } from '@mui/material';


// Interface for DragAndDrop component props
interface DragAndDropProps {
  htmlContent: string;
  onVerify?: (result: boolean) => void;
  onSubmit: () => void;
  multiple?: boolean;
  onSaveProgress?: (data: { placedElements?: Record<string, string[]>; selectedOptions?: string[]; points: number }) => void;
  data?: {  placedElements?: Record<string, string[]>; selectedOptions?: string[]; points: number};
}


// Interface for DropZone
interface DropZone {
  id: string; // Unique ID for the drop zone
  label: string; // Label displayed above the drop zone
  multiple: boolean; // Flag to allow multiple items in a drop zone
}

const shuffleArray = (array: { id: string; text: string }[]) => {
  return [...array] // Kopie des Arrays erstellen
    .map((item) => ({ item, sort: Math.random() })) // Zufallswert hinzufügen
    .sort((a, b) => a.sort - b.sort) // Nach Zufallswert sortieren
    .map(({ item }) => item); // Nur Elemente zurückgeben
};

const DragAndDrop: React.FC<DragAndDropProps> = ({ htmlContent, onVerify, onSubmit, multiple, onSaveProgress, data }) => {
  const theme = useTheme();
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState<number | undefined>(undefined);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [placedElements, setPlacedElements] = useState<Record<string, string[]>>({});
  const [submitted, setSubmitted] = useState(false);
  //const [points, setPoints] = useState<number>(0);
  const [totalPoints, setTotalPoints] = useState<number>(0);
  const [savedPlacedElements, setSavedPlacedElements] = useState<Record<string, string[]>>({});
  const [maxWidth, setMaxWidth] = useState<number>(0);
  // State für das Feedback der einzelnen Drag-Elemente
  // State für das Feedback der einzelnen Drag-Elemente
  const [feedback, setFeedback] = useState<Record<string, 'correct' | 'incorrect' | 'partial' | null>>({});

  const [dragElementWidth, setDragElementWidth] = useState<number | null>(null);


  
 
  
  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
  }, []);

  const parser = new DOMParser(); 
  const doc = parser.parseFromString(htmlContent, 'text/html'); 


  const shuffleArray = <T,>(array: T[]): T[] => {
    return [...array].sort(() => Math.random() - 0.5);
  };
  

  const dragElements = useMemo(() => 
    shuffleArray(
      Array.from(doc.querySelectorAll('.diadibi-drag-element')).map((el) => ({
        id: el.id,
        text: el.textContent?.trim() || '',
      }))
    ), [htmlContent] 
  );
  
  useEffect(() => {
    const tempContainer = document.createElement("div");
    tempContainer.style.position = "absolute";
    tempContainer.style.visibility = "hidden";
    tempContainer.style.display = "flex";
    document.body.appendChild(tempContainer);
  
    let max = 0;
    dragElements.forEach(({ text }) => {
      const tempCard = document.createElement("div");  
      tempCard.style.fontWeight = "bold";  
      tempCard.style.fontSize = "0.9rem";  
      tempCard.style.padding = "0.1rem"; 
      tempCard.style.border = "2px solid transparent"; 
      tempCard.style.width = "auto"; 
      tempCard.style.minWidth ="120px";
      tempCard.textContent = text;
      
      tempContainer.appendChild(tempCard);
      max = Math.max(max, tempCard.offsetWidth); 
    });
  
    document.body.removeChild(tempContainer);
    setMaxWidth(Math.max(120, max + 20)); 
  }, [dragElements]);
  
  

  const dropZones: DropZone[] = Array.from(doc.querySelectorAll('.diadibi-drop-zone')).map((el) => {
    const normalizedId = el.id.trim().toLowerCase(); // 🔹 IDs in Kleinbuchstaben und ohne Leerzeichen speichern
    return {
        id: normalizedId,
        label: el.previousElementSibling?.textContent?.trim() || '',
        multiple: !!Array.from(doc.querySelectorAll(`.diadibi-drag-element[correct-zone="${el.id}"]`)).length,
    };
});

useEffect(() => {
  if (data?.placedElements && Object.keys(data.placedElements).length > 0) {

    setSavedPlacedElements(data.placedElements);
    setPoints(data.points || 0);
    setSubmitted(true);
    setHasVerified(true);

    const newFeedback: Record<string, 'correct' | 'partial' | 'incorrect'> = {};

    const arraysEqual = (arr1: string[], arr2: string[]) => {
      return arr1.length === arr2.length && arr1.every((item) => arr2.includes(item));
    };

    if (data.points === dragElements.length) {
      dropZones.forEach((zone) => {
        newFeedback[zone.id] = 'correct';
      });
    } 

    else if (data.points === 0) {
      dropZones.forEach((zone) => {
        newFeedback[zone.id] = 'incorrect';
      });
    } 
    
    else {
      dropZones.forEach((zone) => {
        const correctItems = Array.from(
          doc.querySelectorAll(`.diadibi-drag-element[correct-zone="${zone.id}"]`)
        ).map((el) => el.id);


        if(data.placedElements){
        const itemsInZone = data.placedElements[zone.id] || []; 
        const correctCount = itemsInZone.filter((itemId) => correctItems.includes(itemId)).length;
        const incorrectCount = itemsInZone.length - correctCount;

        if (zone.multiple) {
          if (correctCount > 0) {
            newFeedback[zone.id] = incorrectCount === 0 
              ? (correctCount === correctItems.length ? 'correct' : 'partial') 
              : 'incorrect';
          }
        } else {
          // Feedback Single Zones
          if (itemsInZone.length === 0) {
            newFeedback[zone.id] = 'incorrect';
          } else {
            newFeedback[zone.id] = arraysEqual(itemsInZone, [zone.id]) ? 'correct' : 'incorrect';
          }
        }
      }});
    }

    setFeedback(newFeedback);

  } 
}, [data]);


  // State for zones: which drag elements are assigned to each drop zone
  const [zones, setZones] = useState<Record<string, string[]>>(
    Object.fromEntries(dropZones.map((zone) => [zone.id, []]))  // Allow multiple elements per drop zone
  );

  // State to track which drag elements are draggable
  const [dragZone, setDragZone] = useState<Record<string, boolean>>(
    Object.fromEntries(dragElements.map((el) => [el.id, true]))  // Initially, all drag elements are draggable
  );

  // State to track feedback for each drop zone (correct/incorrect)
  /*const [feedback, setFeedback] = useState<Record<string, 'correct' | 'incorrect' | 'partial' | null>>(
    Object.fromEntries(dropZones.map((zone) => [zone.id, null])) // Initialisierung bleibt gleich
  );*/
  

  // Points state to store the current score
  const [points, setPoints] = useState<number>(0);
  // State to track whether verification has been clicked
  const [hasVerified, setHasVerified] = useState<boolean>(false);
  // State to track whether verification is complete (to disable dragging)
  const [isVerified, setIsVerified] = useState<boolean>(false);

  // Move element to a drop zone
  const moveElementToZone = (itemId: string, zoneId: string) => {
    if (isVerified || submitted) return; // Falls schon überprüft → kein Dragging mehr
  
    const newZones = { ...zones }; // Kopie des aktuellen Status
    const currentZone = newZones[zoneId]; // Aktuelle Items in der Zone
    const zone = dropZones.find((zone) => zone.id === zoneId); // Die Zone holen
  
    // Falls das Item bereits in der Zone ist, nicht nochmal hinzufügen!
    if (zone?.multiple) {
      if (!currentZone.includes(itemId)) {
        newZones[zoneId] = [...currentZone, itemId]; // Einfügen nur, wenn es nicht existiert
      }
    } else {
      //Falls es kein `multiple` ist, vorheriges Element entfernen
      if (currentZone.length > 0) {
        const replacedItemId = currentZone[0]; // Altes Item
        setDragZone((prev) => ({ ...prev, [replacedItemId]: true })); // Zurück in Drag-Bereich
      }
      newZones[zoneId] = [itemId]; // Direkt ersetzen
    }
  
    //Entferne das Item aus anderen Zonen
    Object.keys(newZones).forEach((otherZoneId) => {
      if (otherZoneId !== zoneId) {
        newZones[otherZoneId] = newZones[otherZoneId].filter((id) => id !== itemId);
      }
    });
  
    setZones(newZones); // Neue Zonen setzen
    setDragZone((prev) => ({ ...prev, [itemId]: false })); // Markieren als platziert
    setFeedback((prev) => ({ ...prev, [zoneId]: null })); // Feedback zurücksetzen
  };
  

  const handleVerify = () => {
    setHasVerified(true);
    setIsVerified(true); // Disable dragging after verification
  
    let score = 0; 
    const newFeedback: Record<string, 'correct' | 'incorrect' | 'partial'> = {};
  
    dropZones.forEach((zone) => {
      const correctItems = Array.from(
        doc.querySelectorAll(`.diadibi-drag-element[correct-zone="${zone.id}"]`)
      ).map((el) => el.id);
  
      const itemsInZone = zones[zone.id] || []; // Elemente im aktuellen Drop-Zone
  
      itemsInZone.forEach((itemId) => {
        if (correctItems.includes(itemId)) {
          newFeedback[itemId] = 'correct'; // ✅ Richtiges Element → grüne Umrandung
          score += 1;
        } else {
          newFeedback[itemId] = 'incorrect'; // ❌ Falsches Element → rote Umrandung
        }
      });
  
      // 🔹 Falls `multiple`, prüfen ob alle korrekten Elemente enthalten sind
      if (zone.multiple) {
        const correctCount = itemsInZone.filter((itemId) => correctItems.includes(itemId)).length;
        const incorrectCount = itemsInZone.length - correctCount;
        newFeedback[zone.id] =
          correctCount > 0 ? (incorrectCount === 0 ? 'correct' : 'partial') : 'incorrect';
      }
      else{
        if(JSON.stringify(itemsInZone) === JSON.stringify([zone.id]))
          {
        setFeedback((prev) => ({ ...prev, [zone.id]: 'correct' }));
        newFeedback[itemsInZone[0]] = "correct";
        score += 1;
      }
        if (JSON.stringify(itemsInZone) !== JSON.stringify([zone.id])) {
           setFeedback((prev) => ({ ...prev, [zone.id]: 'incorrect' }));
           newFeedback[itemsInZone[0]] = "incorrect"; 
           //score -= 1;
      }
      }
    });
  
    setFeedback(newFeedback); // Speichert das Feedback für die Drag-Elemente
    setPoints(Math.max(0, score)); // Stellt sicher, dass Punkte nicht negativ werden
  
    if (onSaveProgress) {
      onSaveProgress({
        placedElements: zones,
        points: Math.max(0, score),
      });
    }
  
    if (onVerify) {
      onVerify(score >= dropZones.length); // Check if all zones are correctly filled
    }
    setIsSubmitted(true);
    onSubmit();
  };
  

  const DragElement = ({ id, text }: { id: string; text: string }) => {
    const [{ isDragging }, drag] = useDrag({
      type: "ELEMENT",
      item: { id },
      canDrag: !isVerified,
      collect: (monitor: any) => ({
        isDragging: monitor.isDragging(),
      }),
    });
  
    const borderColor = feedback[id] === 'correct'
      ? "#4CAF50" // ✅ Grün für korrekt
      : feedback[id] === 'incorrect'
      ? "#F44336" // ❌ Rot für falsch
      : "#ddd";   // 🟡 Standardgrau
  
    return (
<Card
  ref={drag}
  sx={{
    minWidth: "120px", 
    maxWidth: "250px",
    wordWrap: "break-word",
    whiteSpace: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: "0.1rem", 
    borderRadius: "6px", 
    boxShadow: 1, 
    border: `2px solid ${borderColor}`,
    opacity: isDragging ? 0.5 : 1,
    cursor: "grab",
    textAlign: "center", 
    transition: "transform 0.2s ease",
  }}
>
  <CardContent sx={{ padding: "0.2rem" }}> 
    <Typography 
      variant="body2" 
      fontSize="0.9rem"
      sx={{ wordWrap: "break-word", whiteSpace: "normal" }} // 🔹 Hier Textumbruch aktivieren
    >
      {text}
    </Typography>
  </CardContent>
</Card>


    );
  };
  
// Drop zone component
const DropZone = ({ id, label }: DropZone) => {
  const [{ isOver }, drop] = useDrop({
    accept: "ELEMENT",
    drop: (item: { id: string }) => moveElementToZone(item.id, id),
    collect: (monitor: any) => ({
      isOver: monitor.isOver(),
    }),
  });

  const backgroundColor =
    feedback[id] === "correct"
      ? "#C8E6C9"
      : feedback[id] === 'partial'
    ? '#FFEB99'
      : feedback[id] === "incorrect"
      ? "#FFCDD2"
      : isOver
      ? "#e0f7fa"
      : "#fafafa";

  return (
<Paper
  ref={drop}
  elevation={3}
  sx={{
    minWidth: "250px",
    maxWidth: "100%", 
    minHeight: "100px", 
    flex: 1,
    padding: "0.75rem",
    backgroundColor,
    border: "2px solid #ddd",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flexShrink: 0,
    
  }}
>

    {/* 🏷️ Label für Drop-Zone */}
    <Typography lang="de"
  variant="subtitle1" 
  fontWeight="bold"
  sx={{
    wordWrap: "break-word",
    whiteSpace: "normal",
    hyphens:"auto",
    textAlign: "center",
  }}
>
  {label}
</Typography>


    {/* 📦 Platzierte Elemente */}
    <Box
      sx={{
        width: "100%",
        display: "flex",
        //flexWrap: "wrap", 
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        gap: "0.5rem",
      }}
    >
      {savedPlacedElements[id]?.map((itemId) => (
        <DragElement
          key={itemId}
          id={itemId}
          text={dragElements.find((el) => el.id === itemId)?.text || ""}
        />
      ))}
      {zones[id]?.map((itemId) => (
        <DragElement
          key={itemId}
          id={itemId}
          text={dragElements.find((el) => el.id === itemId)?.text || ""}
        />
      ))}
    </Box>
  </Paper>
);
};

/*const titleText = doc.body.firstChild
? doc.body.firstChild.textContent?.replace(/@user\.[a-zA-Z]+/g, '').trim()
: '';*/
const titleText = doc.body.innerHTML.split('<drag-drop>')[0]?.trim();
//console.log("DropZones:", dropZones.map(zone => zone.id));
//console.log("Saved Placed Elements Keys:", Object.keys(savedPlacedElements));


  return (
      <div
        ref={containerRef}
        style={{
          padding: '1.5rem',
          maxWidth: '1000px',
          minWidth: "500px",
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '0.5rem',
          backgroundColor: theme.palette.background.default,
          borderRadius: '2px',
          boxShadow: 'none',
          border: 'none'
        }}
      >
        {/* Display the title text if it exists */}
        {titleText && (
          <h3
            style={{
              marginTop: "0.5rem",
              marginBottom: '0.5rem',
              fontWeight: 'bold',
              fontSize: '1rem',
              color: theme.palette.text.primary,
              textAlign: 'left',
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: titleText }} />
          </h3>
        )}

        {/* Drop-Zonen (Fläche, auf denen die einzelnen Drop Zonen liegen*/}
        <Box
  sx={{
    display: "flex",
    flexWrap: "wrap",
    minWidth: maxWidth,
    gap: "0.5rem",
    justifyContent: "center",
    width: "100%",
    maxWidth: "1000px",
    
    //margin: "auto",
  }}
>
  {dropZones.map(({ id, label, multiple }) => (
    <DropZone key={id} id={id} label={label} multiple={multiple} />
  ))}
</Box>


        <Divider style={{ width: '100%', backgroundColor: theme.palette.grey[300] }} />

        {/* Drag-Elemente */}
        <div
          style={{
            padding: '0.5rem',
            backgroundColor: 'rgba(161, 183, 165, 0.1)',
            borderRadius: '2px',
            flexDirection: "column",
            width: '100%',
            maxWidth: '1000px',
            //display: 'flex',
            flexWrap: 'wrap',
            //gap: '0.75rem',
            justifyContent: 'space-between',
            overflow: 'auto',
          }}
        >
{/* Drag-Elemente (Fläche, in der die Drag Elemente unten liegen*/}
{Object.keys(savedPlacedElements).length === 0 && (
  <div
  style={{
    padding: "0.5rem", 
    backgroundColor: "rgba(161, 183, 165, 0.1)",
    borderRadius: "2px",
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    gap: "0.4rem",
    justifyContent: "center",
    overflow: "auto",
  }}
>
  {dragElements
    .filter(({ id }) => dragZone[id])
    .map(({ id, text }) => (
      <DragElement key={id} id={id} text={text} />
    ))}
</div>

)}

        </div>

        {/* Show points if "Überprüfen" is clicked */}
        {hasVerified || submitted ? (
          <p
            style={{
              padding: '0.5rem 1.5rem',
              color: theme.palette.text.primary,
              fontWeight: 500,
              fontSize: '1rem',
              textAlign: 'center',
            }}
          >
            Erreichte Punkte: {points} von {dragElements.length}
          </p>
        ) : (
          <button
            onClick={handleVerify}
            style={{
              padding: '0.5rem 1.5rem',
              backgroundColor: 'rgb(161, 183, 165)',
              color: '#FFFFFF',
              border: 'none',
              borderRadius: '0.5rem',
              cursor: 'pointer',
              fontWeight: 500,
              fontSize: '0.85rem',
              boxShadow: theme.shadows[1],
              transition: 'background-color 0.2s ease',
            }}
          >
            Überprüfen
          </button>
        )}
        
      </div>

  );
};

export default DragAndDrop;
