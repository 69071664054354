import React from "react";
import { Box, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Header from "ui/components/shared/Header";
import Skillboard from "ui/components/skillboard/Skillboard"; 

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
  textAlign: "center",
  backgroundColor: theme.palette.background.default,
  padding: "2rem",
}));

const SkillboardPage = () => {
  const navigate = useNavigate();

  return (
    <>
      <Header />
      <Wrapper sx={{background:"#f7f7f7"}}>
        <Skillboard />
      </Wrapper>
    </>
  );
};

export default SkillboardPage;
