import React, { useState, useEffect } from "react";
import { Box, Typography, Avatar, Paper, CircularProgress, Tooltip } from "@mui/material";
import WhatshotIcon from "@mui/icons-material/Whatshot"; // 🔥 Icon für Streaks
import FlameIcon from "assets/icons/flame.png"; 
import { POST } from "api/index";
import { useTheme } from "@mui/material/styles";

interface User {
  username: string;
  streak: number;
}
interface WrapperProps {
  children: React.ReactNode;
}


// 🔥 Styled Streak Ranking Card
const StreakWrapper : React.FC<WrapperProps> = ({ children }) =>  (
  
  <Paper elevation={3} sx={{ padding: "1.5rem", borderRadius: "12px", minWidth: "100%" }}>
    {children}
  </Paper>
);

const StreakRanking: React.FC = () => {
  const theme = useTheme();
  const [topUsers, setTopUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const token = window.localStorage.getItem("currentUserToken");

  useEffect(() => {
    const fetchStreaks = async () => {
      try {
        const streakBody = new URLSearchParams();
        streakBody.append("userToken", token || "");

        const response = await POST("getTopStreaks", streakBody);
        if (response.status === 200) {
          setTopUsers(response.top_streaks);
        } else {
          console.error("Fehler beim Laden des Streak Rankings:", response);
        }
      } catch (error) {
        console.error("API-Fehler:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStreaks();
  }, []);

  const formatUsername = (username: string) => {
    if (username.includes("_")) {
      const [firstName, lastName] = username.split("_");
      return `${firstName} ${lastName.charAt(0)}.`; 
    }
    return username;
  };


  return (
    <StreakWrapper>
      <Typography variant="h6" fontWeight="bold" sx={{ mb: 2 }}>
        🔥 Streak Ranking
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : (
        topUsers.map((user, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "0.8rem 1rem",
              borderRadius: "8px",
              backgroundColor: index === 0 ? "#EEC643" : index === 1 ? "#B4B4B4" : index === 2 ? "#AD8A56" : theme.palette.grey[700],
              mb: 1,
              transition: "transform 0.2s ease-in-out",
              "&:hover": { transform: "scale(1.02)" },
            }}
          >
            {/* Rang, Avatar & Name */}
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px", flexGrow: 1 }}>
              <Avatar src={""} sx={{ width: 40, height: 40 }} />
              <Typography variant="body1" fontWeight="bold">
                {index + 1}. {formatUsername(user.username)}
              </Typography>
            </Box>

            {/* Streak & Icon */}
            <Tooltip title="Anzahl aktiver Tage">
              <Box sx={{ display: "flex", alignItems: "center", gap: "5px", minWidth: "80px", justifyContent: "flex-end" }}>
                <img src={FlameIcon} alt="Flame Icon" style={{ width: 24, height: 24 }} />
                <Typography variant="body1" fontWeight="bold">{user.streak} Tage</Typography>
              </Box>
            </Tooltip>
          </Box>
        ))
      )}
    </StreakWrapper>
  );
};

export default StreakRanking;
