import React, { useEffect, useState,  } from "react";
import { useLocation,useParams } from "react-router-dom";
import Header from "ui/components/shared/Header"; 
import { useTheme, alpha } from "@mui/material/styles";

import {
  Box,
  Typography,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  styled,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {POST} from "api/index";
import LearningAccordion from "./LearningAccordion";
// 🎨 Styled Components für das Layout
const Container = styled(Box)({
  maxWidth: "900px",
  margin: "20px auto",
});

const SectionHeader = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: "white",
  padding: "10px 15px",
  fontSize: "1.2rem",
  fontWeight: "bold",
  display: "flex",
  alignItems: "center",
  gap: "10px",
}));
interface LocationState {
    courseTitle?: string;
  }

  const linkify = (text: string) => {
    const urlRegex = /(\b(?:https?:\/\/|www\.)[^\s<>"')]+[^\s<>"')\.,])/gi;
  
    return text.replace(urlRegex, (url) => {
      const href = url.startsWith("http") ? url : `https://${url}`;
      return `<a href="${href}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
  };
  
  

const SkillboxLearnings = () => {
  const { courseId } = useParams(); // Holt die Kurs-ID aus der URL
  const [chapters, setChapters] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation() as { state?: LocationState }; 
  const storedTitle = localStorage.getItem("currentCourseTitle");
  const courseTitle = location.state?.courseTitle || storedTitle || `Kurs ${courseId}`;
  const [completedChapters, setCompletedChapters] = useState<number[]>([]);
  const token = window.localStorage.getItem("currentUserToken");
  const [learningCounts, setLearningCounts] = useState<{ [key: string]: number }>({});
  const [extensions, setExtensions] = useState<{ sources: string[]; tasks: string[] }>({ sources: [], tasks: [] });

  const theme = useTheme();



  const getLearningCount = async (chapterId: number) => {
    try {
      if (!chapterId) return 0;

      const body = new URLSearchParams();
      body.append("chapter_id", chapterId.toString());

      const response = await POST("getSkillboxLearningCount", body);
      if (!response || typeof response.learning_count === "undefined") {
        return 0;
      }

      console.log(`Learning Count für Kapitel ${chapterId}:`, response.learning_count);

      // 🆕 Update `learningCounts` State
      setLearningCounts(prev => ({ ...prev, [chapterId]: response.learning_count }));

      return response.learning_count;
    } catch (error) {
      console.error("Fehler beim Abrufen der Learnings:", error);
      return 0;
    }
  };



  const getCompletedChapters = async (token: string | null) => {
    try {
        const body = new URLSearchParams();
        if (token) body.append("userToken", token);
        
        const response = await POST("getCompletedChapters", body);
        if (!response || !response.completed_chapters) {
          console.warn(" Keine abgeschlossenen Kapitel gefunden.");
          return [];
      }
      const completedChapterIds = response.completed_chapters.map(
          (chapter: { chapter_id: number }) => chapter.chapter_id
      );
      setCompletedChapters(completedChapterIds);
      return completedChapterIds;
    } catch (error) {
        console.error(" Fehler beim Abrufen der abgeschlossenen Kapitel:", error);
        return [];
    }
};



useEffect(() => {
  const fetchLearnings = async () => {
    try {
      // Kapitel des aktuellen Kurses abrufen
      const chaptersRes = await fetch("https://api.diadibi.net/getChapters", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({ option: courseId || "" }),
      });
      const chaptersData = await chaptersRes.json();
      if (!chaptersData.result) return;

      // Abgeschlossene Kapitel holen
      const completedChapterIds = await getCompletedChapters(token);
      console.log("Abgeschlossene Kapitel:", completedChapterIds);

      // Nur Kapitel dieses Kurses nehmen & nach `ordering` sortieren
      const sortedChapters = [...chaptersData.result].sort(
        (a: any, b: any) => parseInt(a.ordering) - parseInt(b.ordering)
      );

      // Letztes abgeschlossenes Kapitel bestimmen
      let lastCompletedIndex = -1;
      sortedChapters.forEach((ch, index) => {
        if (completedChapterIds.includes(Number(ch.id))) {
          lastCompletedIndex = index;
        }
      });

      console.log("Letztes freigeschaltetes Kapitel:", sortedChapters[lastCompletedIndex]?.id);

      // Kapitel durchgehen & automatisch bis zum letzten freischalten
      const enrichedChapters = await Promise.all(
        sortedChapters.map(async (chapter, index) => {
          const isUnlocked = index <= lastCompletedIndex; // Alles bis zum letzten freischalten

          let learnings = [];
          if (isUnlocked) {
            console.log("Kapitel freigeschaltet:", chapter.id);
            const learningsRes = await fetch("https://api.diadibi.net/getSkillboxContent", {
              method: "POST",
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
              body: new URLSearchParams({ chapter_id: chapter.id }),
            });
            const learningsData = await learningsRes.json();
            learnings = learningsData.skillbox || [];
          } else {
            console.log("🔒 Kapitel gesperrt:", chapter.id);
          }
          // Skillbox-Erweiterungen laden
          try {
            const extRes = await fetch("https://api.diadibi.net/getSkillboxExtensions", {
              method: "POST",
              headers: { "Content-Type": "application/x-www-form-urlencoded" },
              body: new URLSearchParams({ course_id: courseId || "" }),
            });
            const extData = await extRes.json();
            if (extData.extensions) {
              setExtensions({
                sources: extData.extensions.sources || [],
                tasks: extData.extensions.tasks || [],
              });
            }
          } catch (error) {
            console.error("Fehler beim Laden der Erweiterungen:", error);
          }
          

          return { 
            ...chapter, 
            learnings,
            locked: !isUnlocked,
          };
        })
      );

      setChapters(enrichedChapters);
      sortedChapters.forEach(chapter => {
        getLearningCount(chapter.id);
      });
    } catch (error) {
      console.error("Fehler beim Laden der Inhalte:", error);
    } finally {
      setLoading(false);
    }
  };
  console.log(chapters)
  fetchLearnings();
}, [courseId]);


return (
  <>
    <Header />
    <Container sx={{ marginTop: "5.5rem" }}>
      <SectionHeader>Learnings: {courseTitle}</SectionHeader>

      {loading ? (
        <CircularProgress />
      ) : chapters.length > 0 ? (
        <>
          {chapters.map((chapter) => (
            <LearningAccordion 
              key={chapter.id} 
              chapter={{ ...chapter, title: `${chapter.title}` }}
            />
          ))}

          {/* Erweiterungen anzeigen */}
          {(extensions.sources.length > 0 || extensions.tasks.length > 0) && (
            <Box sx={{ mt: 2 }}>
            {extensions.sources.length > 0 && (
              <Accordion
  defaultExpanded
  sx={{
    borderRadius: "8px",
    border: "1px solid #ddd",
    boxShadow: 1,
    backgroundColor: "#ffffff",
    marginBottom: "10px",
  }}
>
<AccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ marginLeft: "1.5rem" }} />}
    sx={{
      backgroundColor: alpha(theme.palette.primary.main, 0.67),
      minHeight: "56px",
    }}
  >
    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
      <Typography fontWeight="bold" fontSize="1.1rem">
        🔍 Wissen erweitern
      </Typography>
    </Box>
  </AccordionSummary>

  <AccordionDetails>
    <Box
      sx={{
        backgroundColor: "#f9f9f9",
        padding: "15px",
        borderRadius: "8px",
        boxShadow: 1,
      }}
    >
<ul style={{ paddingLeft: "20px", lineHeight: "1.6", listStyleType: "square" }}>
{extensions.sources.map((item, idx) => (
  <li
    key={idx}
    style={{ marginBottom: "8px", fontSize: "1.05rem" }}
    dangerouslySetInnerHTML={{ __html: linkify(item) }}
  />
))}

</ul>

    </Box>
  </AccordionDetails>
</Accordion>

            )}
          
            {extensions.tasks.length > 0 && (
              <Accordion
  defaultExpanded
  sx={{
    borderRadius: "8px",
    mb: 2,
    border: "1px solid #ddd",
    boxShadow: 1,
    backgroundColor: "#ffffff", // außen wieder weiß
  }}
>
  <AccordionSummary
    expandIcon={<ExpandMoreIcon sx={{ marginLeft: "1.5rem" }} />}
    sx={{
      backgroundColor: alpha(theme.palette.primary.main, 0.67),
      minHeight: "56px",
    }}
  >
    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%",  }}>
    <Typography fontWeight="bold" fontSize="1.1rem">🛠️ Skill umsetzen</Typography>
    </Box>
  </AccordionSummary>

              <AccordionDetails>
                <Box
                  sx={{
                    backgroundColor: "#f9f9f9",
                    padding: "15px",
                    borderRadius: "8px",
                    boxShadow: 1,
                  }}
                >
                  <ul style={{ paddingLeft: "20px", lineHeight: "1.6", listStyleType: "square" }}>
                    {extensions.tasks.map((item, idx) => (
                      <li key={idx} style={{ marginBottom: "8px", fontSize: "1.05rem" }}>
                        {item}
                      </li>
                    ))}
                  </ul>
                </Box>
              </AccordionDetails>
            </Accordion>
            
            )}
          </Box>
          
          )}
        </>
      ) : (
        <Typography>Keine Kapitel gefunden.</Typography>
      )}
    </Container>
  </>
);
}
export default SkillboxLearnings;
