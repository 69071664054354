import React, { useContext, useState, useEffect } from "react";
import {useNavigate} from "react-router-dom";
import { Box, Divider, Grid, Button, IconButton, Typography, styled, useTheme } from "@mui/material";
import ImageComponent from "ui/components/shared/ImageComponent";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import DeleteIcon from "assets/icons/trash.png";
import AddSkillIcon from 'assets/icons/message-circle.png'
import AddSkillDisabledIcon from 'assets/icons/message-circle-disabled.png'
import { TextSizes } from "entities/constants";
import { TextSizeContext } from "data/index";
import { ParagraphInterface, SkillboxCourseInterface } from "entities/interfaces";
import ConfirmationModal from "ui/components/shared/ConfirmationModal";
import { POST } from "api/index";


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "1rem",
  width: '18.8125rem',
  borderRadius: "1rem",
  border: `1px solid ${theme.palette.primary.main}`,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'transparent',
  },
}));

const Wrapper = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.grey[800],
  padding: "0 1.5rem",
  height: "7.75rem",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  boxShadow: '0px 0px 1px 0px rgba(144, 132, 174, 0.10), 0px 2px 2px 0px rgba(144, 132, 174, 0.09), 0px 4px 2px 0px rgba(144, 132, 174, 0.05), 0px 6px 3px 0px rgba(144, 132, 174, 0.01), 0px 10px 3px 0px rgba(144, 132, 174, 0.00)'
}));

const Label = styled(Typography)(() => ({
  fontWeight: 500,
}));

const Progress = styled(Typography)(() => ({
  fontWeight: 400,
  textAlign: "center",
}));

const GridItem = styled(Grid)(() => ({
  display: "flex",
  alignItems: 'center',
  gap: "1.5rem",
}));

const ExpandBox = styled(Box)(({theme}) => ({
  backgroundColor:theme.palette.mode === 'light' ? theme.palette.grey[900] : theme.palette.grey[600],
  display: "flex",
  flexDirection: "column",
  marginBottom: '0.2rem'
}));

const SkillboxRow = ({ skill, addUserCourse, deleteUserCourse, setChapterNote,
  getChapterNote, getChapterHistory, getChapterMaterial }: {
    skill: SkillboxCourseInterface,
    addUserCourse: (courseId: number) => Promise<number>,
    deleteUserCourse: (courseId: number) => Promise<number>
    setChapterNote: (chapterId: number, note: string) => Promise<void>;
    getChapterNote: (chapterId: number) => Promise<string>
    getChapterHistory: (chapterId: number) => Promise<ParagraphInterface[]>
    getChapterMaterial: (chapterId: number) => void
  }) => {
  const { state: textSize } = useContext(TextSizeContext)
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const theme = useTheme()
  const navigate = useNavigate();
  const token = window.localStorage.getItem("currentUserToken");
  const [completedChapters, setCompletedChapters] = useState<number[]>([]);
  
  const [progress, setProgress] = useState(0);
  const [totalLearnings, setTotalLearnings] = useState(0);
  const [cachedCompletedChapters, setCachedCompletedChapters] = useState<number[]>([]);
  

  const getCompletedChapters = async (token: string | null) => {
    try {
        const body = new URLSearchParams();
        if (token) body.append("userToken", token);
        
        const response = await POST("getCompletedChapters", body);
        if (!response || !response.completed_chapters) {
          console.warn("⚠️ Keine abgeschlossenen Kapitel gefunden.");
          return [];
      }
      const completedChapterIds = response.completed_chapters.map(
          (chapter: { chapter_id: number }) => chapter.chapter_id
      );
      setCompletedChapters(completedChapterIds);
      return completedChapterIds;
    } catch (error) {
        console.error("Error Fetching Chapters:", error);
        return [];
    }
};

useEffect(() => {
  const fetchProgress = async () => {
    try {
      const completed = await getCompletedChapters(token);
      setCompletedChapters(completed);

      setProgress(skill.progress);

      const unlockedChapters = skill.chapters.filter(chapter =>
        completed.includes(Number(chapter.id))
      );


      const learningCounts = await Promise.all(
        unlockedChapters.map(async (chapter) => {
          const learningsBody = new URLSearchParams();
          if (token) learningsBody.append("userToken", token);
          learningsBody.append("chapter_id", chapter.id.toString());

          const learningResponse = await POST("getSkillboxLearningCount", learningsBody);
          return learningResponse.learning_count || 0;
        })
      );

      const total = learningCounts.reduce((sum, count) => sum + count, 0);
      setTotalLearnings(total);

    } catch (error) {
      console.error("Fehler beim Abrufen der Fortschrittsdaten:", error);
    }
  };

  fetchProgress();
}, [skill]); 

  
return (
  <Box>
    <Wrapper container sx={{ borderRadius: '2px' }}>
      {/* Kurstitel + Kapitelanzahl */}
      <GridItem item lg={5} md={4.5}>
        <ImageComponent
          src={skill.thumbnail}
          alt="Logo"
          height="3.75rem"
          width="3.75rem"
          borderRadius="50%"
        />
        <Box>
          <Label fontSize={TextSizes[textSize].title3}>
            {skill.title}
          </Label>
          <Typography variant="body2" color="textSecondary">
            {skill.chapters.length} Kapitel
          </Typography>
        </Box>
      </GridItem>

      {/* Fortschrittsanzeige + Learnings */}
      <GridItem item lg={6} md={5} sx={{ gap: '0.75rem', display: "flex", alignItems: "center" }}>
        <BorderLinearProgress variant="determinate" value={progress} sx={{  width: "18rem" }} />
        <Progress fontSize={TextSizes[textSize].callout} sx={{ width: "3rem", textAlign: "right" }}>
          {progress.toFixed(0)}%
        </Progress>

        <Button
          variant="contained"
          color="success"
          sx={{ minWidth: "9rem", justifyContent: "center", textAlign: "center", marginLeft: "10rem", textTransform: "none", }}
          onClick={() => {
            navigate(`/skillbox/${skill.id}`, { state: { courseTitle: skill.title } });
          }}
        >
          {totalLearnings} Learnings
        </Button>
      </GridItem>

      {/* Icons für Kurs hinzufügen/löschen */}
      <GridItem item lg={1} md={2.5} sx={{ justifyContent: 'flex-end', gap: "0.8rem" }}>
        <IconButton onClick={() => addUserCourse(skill.id)} disabled={skill.state.toString() === '1'}>
          <ImageComponent
            src={skill.state.toString() === '1' ? AddSkillDisabledIcon : AddSkillIcon}
            alt="add"
            width="1.5rem"
            height="1.5rem"
            filterAllowed
          />
        </IconButton>
        <IconButton onClick={() => { setDeleteConfirmationModal(true) }}>
          <ImageComponent
            src={DeleteIcon}
            alt="delete"
            width="1.5rem"
            height="1.5rem"
          />
        </IconButton>
      </GridItem>
    </Wrapper>

    <Divider />

    {deleteConfirmationModal && (
      <ConfirmationModal
        closeModal={() => setDeleteConfirmationModal(false)}
        confirmColor="error"
        handleConfirm={async () => {
          await deleteUserCourse(skill.id);
          setDeleteConfirmationModal(false);
        }}
        title="Kurs löschen"
        description={`Sind Sie sicher, dass Sie ${skill.title} löschen wollen?`}
      />
    )}
  </Box>
);


};

export default SkillboxRow;
