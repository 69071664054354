import React, { useState, useEffect } from "react";
import { useDrag, useDrop } from "react-dnd/dist";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Button, Box, Paper, Typography } from "@mui/material";

interface Step {
    id: string;
    text: string;
}

interface SortTheParagraphProps {
    htmlContent: string;
    onSubmit: () => void;
    onVerify?: (result: boolean) => void;
    onSaveProgress?: (data: { placedElements?: Record<string, string[]>; selectedOptions?: string[]; points: number }) => void;
    data?: { placedElements?: Record<string, string[]>; selectedOptions?: string[]; points: number };
}
interface SortableItemProps {
    step: Step;
    index: number;
    moveStep: (dragIndex: number, hoverIndex: number) => void;
    isCorrect: boolean | null;
    hasChecked: boolean;
    stepFeedback: Record<string, "correct" | "incorrect" | null>;
}


const ItemType = "STEP";

const shuffleArray = <T,>(array: T[]): T[] => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };
  
const SortTheParagraph: React.FC<SortTheParagraphProps> = ({ htmlContent, onVerify, onSubmit, onSaveProgress, data }) => {
    const parsedContent = typeof htmlContent === "string" ? JSON.parse(htmlContent) : htmlContent;
    const correctOrder = parsedContent.correct_order || [];
    const [feedback, setFeedback] = useState<string | null>(null);
    const [hasChecked, setHasChecked] = useState<boolean>(false);
    

    
    const [stepFeedback, setStepFeedback] = useState<Record<string, "correct" | "incorrect" | null>>({});
    
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isCorrect, setIsCorrect] = useState<boolean | null>(submitted ? true : null);
    const [earnedPoints, setEarnedPoints] = useState(0);
    
    const [steps, setSteps] = useState<Step[]>(() => {
        if (data?.placedElements && Object.keys(data.placedElements).length > 0) {
            setHasChecked(true)
            setEarnedPoints(data.points)
            return Object.values(data.placedElements)
                .flat()
                .map(stepId => parsedContent.steps.find((step: Step) => step.id === stepId))
                .filter((step): step is Step => step !== undefined);
        }
        return shuffleArray(parsedContent.steps) || [];
    });



     

    useEffect(() => {
        if (!parsedContent?.steps) return; 
        if (!data || !data.placedElements || typeof data.placedElements !== "object") {
            console.error("Fehler: placedElements fehlt oder ist kein Objekt!", data);
            return;
        }
        // Extrahiere alle Step-IDs (flach machen, falls Arrays enthalten sind)
        const placedElementsArray = Object.values(data.placedElements).flat();

        if (placedElementsArray.length > 0) {
            setSteps(
                placedElementsArray
                    .map(stepId => parsedContent.steps.find((step: Step) => step.id === stepId))
                    .filter((step): step is Step => step !== undefined)
            );
            const isCorrectlyOrdered = JSON.stringify(placedElementsArray) === JSON.stringify(correctOrder);
            const newStepFeedback: Record<string, "correct" | "incorrect" | null> = {};
            steps.forEach((step, index) => {
                newStepFeedback[step.id] = step.id === correctOrder[index] ? "correct" : "incorrect";
            });
            setStepFeedback(newStepFeedback);

            if (isCorrectlyOrdered) {
                setSubmitted(true);
                setIsCorrect(true);
                setHasChecked(true);
                setFeedback(parsedContent.feedback.correct);
            } else {
                setIsCorrect(false);
                setFeedback(parsedContent.feedback.incorrect);
            }
        }
    }, [data, parsedContent]);



    const moveStep = (dragIndex: number, hoverIndex: number) => {
        if (hasChecked) return;
        const updatedSteps = [...steps];
        const [movedStep] = updatedSteps.splice(dragIndex, 1);
        updatedSteps.splice(hoverIndex, 0, movedStep);
        setSteps(updatedSteps);
    };

    const handleVerify = () => {
        if (hasChecked) return; 

        const currentOrder = steps.map(step => step.id);
        const isCorrectAnswer = JSON.stringify(currentOrder) === JSON.stringify(correctOrder);

        const newFeedback: Record<string, "correct" | "incorrect" | null> = {};
        steps.forEach((step, index) => {
            newFeedback[step.id] = currentOrder[index] === correctOrder[index] ? "correct" : "incorrect";
        });

        setStepFeedback(newFeedback);
        setFeedback(isCorrectAnswer ? parsedContent.feedback.correct : parsedContent.feedback.incorrect);
        setIsCorrect(isCorrectAnswer);
        setHasChecked(true);
        const points = steps.filter((step, index) => step?.id === correctOrder[index]).length;
        setEarnedPoints(points);
        
        const placedElements = Object.fromEntries(
            steps.map((step, index) => [index.toString(), [step.id]])
          );
     
            setSubmitted(true);
            onSaveProgress?.({
                placedElements,
                points,
              });
              

            onSubmit();
        
    };


    return (
        <Box sx={styles.container}>
          <Typography variant="h5" sx={styles.title}>
            {parsedContent.question}
          </Typography>
      
          {/* Sortierbare Liste */}
          <Box component="ul" sx={styles.list}>
            {steps.map((step, index) => (
              <SortableItem 
                key={step.id} 
                index={index} 
                step={step} 
                moveStep={moveStep} 
                isCorrect={isCorrect} 
                hasChecked={hasChecked} 
                stepFeedback={stepFeedback} 
              />
            ))}
          </Box>
      
          {/* Überprüfen-Button */}
          <Box sx={{ marginBottom: "0.5rem" }}>
            {!hasChecked && (
              <Button
                onClick={handleVerify}
                variant="contained"
                sx={{
                  padding: "0.5rem 1.5rem",
                  backgroundColor: "rgb(161, 183, 165)",
                  color: "#FFFFFF",
                  borderRadius: "0.5rem",
                  cursor: "pointer",
                  fontWeight: 500,
                  fontSize: "0.85rem",
                  boxShadow: 1,
                  textTransform: "none",
                  transition: "background-color 0.2s ease",
                  marginTop: "1.5rem",
                  "&:hover": {
                    backgroundColor: "rgb(140, 165, 145)",
                  },
                }}
              >
                Überprüfen
              </Button>
            )}
          </Box>
      
          {/* Feedback + Punkteanzeige */}
          {(hasChecked && feedback) && (
            <Box sx={{ textAlign: "center", marginTop: "1rem" }}>
              <Typography sx={isCorrect ? styles.correct : styles.incorrect}>
                {/*feedback*/}
              </Typography>
              <Typography sx={{ fontWeight: 500, fontSize: "1rem", marginTop: "0.5rem" }}>
                Erreichte Punkte: {earnedPoints} von {steps.length}
              </Typography>
            </Box>
          )}
        </Box>
      );
      
};

interface SortableItemProps {
    step: Step;
    index: number;
    moveStep: (dragIndex: number, hoverIndex: number) => void;
    isCorrect: boolean | null;
    hasChecked: boolean;
}
const SortableItem: React.FC<SortableItemProps> = ({ step, index, moveStep, isCorrect, hasChecked, stepFeedback }) => {
    const ref = React.useRef<HTMLLIElement>(null);

    const [{ isDragging }, drag] = useDrag({
        type: ItemType,
        item: { index },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const [, drop] = useDrop({
        accept: ItemType,
        hover: (draggedItem: { index: number }) => {
            if (draggedItem.index !== index) {
                moveStep(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
    });

    drag(drop(ref));

    return (
        <Paper
            ref={ref}
            component="li"
            sx={{
                backgroundColor: stepFeedback[step.id] === "correct"
                    ? "#C8E6C9"  // ✅ Grün für richtig
                    : stepFeedback[step.id] === "incorrect"
                        ? "#FFCDD2"  // ❌ Rot für falsch
                        : "#98A99A", // 🔘 Neutral vor dem ersten Check
                color: stepFeedback[step.id] ? "#333" : "white",
                padding: "12px",
                margin: "8px 0",
                cursor: "grab",
                borderRadius: "8px",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                transition: "background-color 0.3s ease",
                boxShadow: isDragging ? "0px 4px 10px rgba(0, 0, 0, 0.2)" : "none",
                opacity: isDragging ? 0.5 : 1,
                "&:hover": {
                    backgroundColor: hasChecked
                        ? isCorrect === true
                            ? "#A5D6A7"
                            : "#E57373"
                        : "#789D89",
                },
            }}
        >
            {step.text}
        </Paper>
    );
};


const styles = {
    container: {
        textAlign: "center",
        fontFamily: "Arial, sans-serif",
        padding: "1.5rem",
        maxWidth: "600px",
        margin: "auto",
        backgroundColor: "#fff",
        borderRadius: "10px",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    },
    title: {
        fontWeight: "bold",
        color: "#333",
        marginBottom: "1rem",
    },
    list: {
        listStyle: "none",
        padding: 0,
        marginBottom: "1rem",
    },
    listItem: (isCorrect: boolean | null) => ({
        backgroundColor: isCorrect === true ? "#C8E6C9" : "#98A99A",
        color: isCorrect === true ? "#333" : "white",
        padding: "12px",
        margin: "8px 0",
        cursor: "grab",
        borderRadius: "8px",
        fontWeight: "bold",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transition: "background-color 0.3s ease",
        "&:hover": {
            backgroundColor: isCorrect === true ? "#A5D6A7" : "#789D89",
        },
    }),
    button: {
        marginTop: "20px",
        padding: "10px",
        backgroundColor: "#98A99A", // Standard-Farbe
        "&:hover": {
            backgroundColor: "#7C8C7C",
        },
        color: "white",
        fontSize: "16px",
        cursor: "pointer",
        borderRadius: "5px",
        fontWeight: "bold",
    },
    correct: {
        color: "#4CAF50",
        fontWeight: "bold",
        marginTop: "10px",
    },
    incorrect: {
        color: "#D32F2F",
        fontWeight: "bold",
        marginTop: "10px",
    },
};

export default SortTheParagraph;
