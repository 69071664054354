import React, { useContext, useEffect, useState } from 'react'
import { Box,Button, IconButton, Typography, styled, useTheme } from '@mui/material'
//import SkillynxLogo from 'assets/logos/skillynx.png'
import SkillynxLogo from 'assets/logos/logo_bold.png'
import ImageComponent from './ImageComponent'
import useScreenSize from 'hooks/ScreenSize'
import MenuIcon from 'assets/icons/menu.png'
import MenuIconDark from 'assets/icons/Menu-Dark.png'
import { MobileWidth, TextSizes } from 'entities/constants'
import { useTranslation } from 'react-i18next'
import { TextSizeContext } from 'data/index'
import { DrawerPropType } from 'entities/interfaces'
import LogoutIcon from 'assets/icons/Logout.png'
import LogoutIconDark from 'assets/icons/Logout-dark.png'
import { Unstable_Popup } from '@mui/base/Unstable_Popup';
import ScoreIcon from 'assets/icons/scoreIcon.png' 
import FlameIcon from 'assets/icons/flame.png'
import { useNavigate } from 'react-router-dom'
import { POST } from 'api/index';
import { usePoints } from "context/PointsContext"; 



const HeaderBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: '1rem 1.25rem 1rem 1rem',
  width: '100%', 
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between', 
  boxShadow: theme.palette.mode === 'light' ? '0px 4px 8px rgba(0, 0, 0, 0.1)' : '',
  zIndex: '1000',
  height: '5rem', 
  position: 'fixed', 
  top: 0,
  left: 0,
}));


const StatsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  border: '2px solid #a3b3a3',
  borderRadius: '12px',
  overflow: 'hidden',
  height: '3.5rem',
  minWidth: '16rem',
  padding: '0.2rem 0.5rem', 
}));

const StatBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '50%',
  gap: '0.5rem',
  padding: '0.3rem 0.8rem',
  backgroundColor: theme.palette.common.white,
  transition: 'transform 0.2s, box-shadow 0.2s',
  cursor: 'pointer',
  '&:hover': {
    transform: 'scale(1.05)',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.3)',
    backgroundColor: theme.palette.grey[100],
  },
}));

const UserIcon = styled('button')(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  borderRadius: '50%',
  width: '3rem',
  height: '3rem',
  fontWeight: 500,
  fontSize: '1.5rem',
  border: 'none',
  color: theme.palette.common.white,
  marginLeft: '3.5rem',
  cursor: 'pointer', 
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'scale(1.1)', 
    backgroundColor: theme.palette.grey[300],
  },
}));

const TitleBox = styled(Box)(({ theme }) => ({
  marginLeft: '3rem',
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  flexGrow: '1',
  gap: '1rem'
}));
const OptionsPopup = styled(Unstable_Popup)(({ theme }) => ({
  paddingLeft: '2rem',
  zIndex: '999999999',
}))

const IconWrapper = styled(Box)(({ theme }) => ({
  width: '2.5rem', 
  height: '2.5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const OptionsBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  display: 'flex',
  flexDirection: 'column',
  width: '108px',
  borderRadius: '4px',
  boxShadow: '0px 0px 16px 4px rgba(127, 127, 127, 0.3)',
  padding: '10px 0',
}))

const Option = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  width: '100%',
  height: '40px',
  display: 'flex',
  padding: '4px 12px 4px 20px',
  gap: '8px',
  textTransform: 'none',
  justifyContent: 'flex-start'
}))

const OptionsArrowBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  position: 'absolute',
  marginTop: '-15px',
  marginLeft: '30px',
  width: '12px',
  height: '12px',
  transform: 'rotate(45deg)',
  flexShrink: 0
}))


const CoinIconWrapper = styled(Box)(({ theme }) => ({
  width: '2.5rem',
  height: '2.5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StatText = styled(Typography)(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: '700',
  color: theme.palette.grey[700],
}));

const Divider = styled(Box)(({ theme }) => ({
  width: '2px',
  backgroundColor: '#a3b3a3',
  height: '80%',
  margin: '0 0.5rem',
}));

const Header = ({ 
  data, 
  isSetting = false, 
  totalScore, 
  currentStreak,
  chapterTitle
}: { 
  data?: DrawerPropType, 
  isSetting?: boolean, 
  totalScore?: number, 
  currentStreak?: number,
  chapterTitle?: string
}) => {
  const navigate = useNavigate();
  const { width } = useScreenSize();
  const { t } = useTranslation();
  const theme = useTheme();
  const { state: textSize } = useContext(TextSizeContext);
  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);
  const [streak, setStreak] = useState<number>(0);
  const [usertotalPoints, setTotalPoints] = useState<number>(0);
  const { totalPoints } = usePoints();


  const getUserStreak = async () => {
    const body = new URLSearchParams();
    body.append('userToken', localStorage.getItem('currentUserToken') || '');
    const response = await POST('getUserStreak', body); 
    if (response.status === 200) {
        setStreak(response.streak);
    }
};



useEffect(() => {
  const fetchAndUpdateStreak = async () => {
      const userToken = localStorage.getItem('currentUserToken');
      if (!userToken) return;
      const body = new URLSearchParams();
      body.append('userToken', userToken);
      const updateResponse = await POST('setUserStreak', body);
     getUserStreak();
  };

  fetchAndUpdateStreak();
}, []);


  const handleMore = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchor(anchor ? null : event.currentTarget);
  };

  const open = Boolean(anchor);
  const id = open ? 'simple-popper' : undefined;

  const handleLogout = () => {
    localStorage.removeItem('currentUserToken');
    localStorage.removeItem('currentUserName');
    navigate('/auth');
  };

  const handleUserIconClick = () => {
    navigate('/settings');
  };


  return (
<HeaderBox justifyContent={width > MobileWidth ? 'flex-start' : 'space-between'}>
  {width > MobileWidth ? (
    <>
      {/* Logo */}
      <Box sx={{ width: '16.62rem', paddingTop: '0.4rem' , marginLeft: '2rem' }}>
        <ImageComponent src={SkillynxLogo} alt='Logo' height='43px' width='158.052px' />
      </Box>

      {/* Titel */}
      <TitleBox>
        {data?.title && (
          <>
            <ImageComponent 
              src={theme.palette.mode === 'dark' && data.thumbnailDark ? data.thumbnailDark : data.thumbnail} 
              alt='skill' 
              width={isSetting ? '3rem' : '3.75rem'} 
              height={isSetting ? '3rem' : '3.75rem'} 
              borderRadius={isSetting ? '' : '50%'} 
            />
<Typography 
  sx={{ fontWeight: '700', fontSize: TextSizes[textSize].title3, color: theme.palette.common.black }}
>
  {t(data.title)}
  {chapterTitle && ` - ${t(chapterTitle)}`}
 
  </Typography>
          </>
        )}
      </TitleBox>

      {/* Streak & Score Container */}
      <StatsContainer>
        {/* Streak */}
        <StatBox>
              <IconWrapper>
                <ImageComponent src={FlameIcon} alt="Streak Icon" width="100%" height="100%" />
              </IconWrapper>
              <StatText>{streak}</StatText>
              <StatText>{currentStreak}</StatText>
            </StatBox>

        {/* Trennlinie (jetzt in Grün) */}
        <Divider />

        {/* Punkte  */}
        <StatBox>
              <CoinIconWrapper>
                <ImageComponent src={ScoreIcon} alt="Score Icon" width="100%" height="100%" />
              </CoinIconWrapper>
              <StatText>{totalPoints}</StatText>
            </StatBox>
          </StatsContainer>


      {/* Benutzer-Icon mit Abstand */}
      <UserIcon  onClick={handleUserIconClick} sx={{ ml: '15rem' }}>{window.localStorage.getItem("currentUserName")?.charAt(0)}</UserIcon>

    </>
  ) : (
    <>
      <IconButton onClick={handleMore}>
        <ImageComponent src={theme.palette.mode==='light'? MenuIcon:MenuIconDark} alt='menu' width='1.5rem' height='1.5rem' />
      </IconButton>
      <ImageComponent src={SkillynxLogo} alt='Logo' height='2rem' width='7.1875rem' />
      <UserIcon sx={{ fontSize: '1.5rem' }}>{window.localStorage.getItem("currentUserName")?.charAt(0)}</UserIcon>
      <OptionsPopup id={id} open={open} anchor={anchor}>
        <OptionsBox>
          <Option sx={{ fontSize: TextSizes[textSize].subhead }}  onClick={handleLogout} >
            <ImageComponent src={theme.palette.mode==='light'?LogoutIcon:LogoutIconDark} alt='icon' width='1.25rem' height='1.25rem' />
            {t('Logout')}
          </Option>
          <OptionsArrowBox />
        </OptionsBox>
      </OptionsPopup>
    </>
  )}
</HeaderBox>
)

}

export default Header
