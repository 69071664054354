import React from "react";
import { Box, Button, Typography, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Header from "ui/components/shared/Header";
import { useTransform } from "framer-motion";
import { useTranslation } from "react-i18next";

const Wrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  textAlign: "center",
  backgroundColor: theme.palette.background.default,
}));

const MessageBox = styled(Box)(({ theme }) => ({
  background: theme.palette.mode === "light" ? "#ffffff" : theme.palette.grey[800],
  padding: "2rem 3rem",
  borderRadius: "12px",
  boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
}));

const BackButton = styled(Button)(({ theme }) => ({
  marginTop: "1.5rem",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: "0.8rem 2rem",
  fontSize: "1rem",
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const Skillcards = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  return (
    <>
    <Header />
    <Wrapper>
      <MessageBox>
        <Typography variant="h4" fontWeight="bold">
        {t("🚧 Page Under Construction 🚧")}
        </Typography>
        <Typography variant="body1" color="textSecondary" sx={{ marginTop: "0.5rem" }}>
        {t("This feature is not available yet. Please check back later to learn more!")}
        </Typography>
        <BackButton onClick={() => navigate("/Library")}>{t("Back to Home")}</BackButton>
      </MessageBox>
    </Wrapper>
    </>
  );
};

export default Skillcards;
